import axios from "axios";
import { getHeadersForRequest } from "./functions";
import { AccountList, CustomerList, ProductList, SupplierList } from "./types";

export const getCustomerList = async (): Promise<{
  data: CustomerList[];
  success: number;
}> => {
  try {
    const headers = await getHeadersForRequest();
    const response = await axios.get<CustomerList[]>(
      `${process.env.REACT_APP_TEST_API_URL}/customers/getall`,
      { headers }
    );
    return { data: response.data, success: 1 };
  } catch (error) {
    console.error(error);
    return { data: [], success: 0 }; // Return an empty array instead of null
  }
};

export const getSupplierList = async (): Promise<{
  data: SupplierList[];
  success: number;
}> => {
  try {
    const headers = await getHeadersForRequest();
    const response = await axios.get<SupplierList[]>(
      `${process.env.REACT_APP_TEST_API_URL}/suppliers/getall`,
      { headers }
    );
    return { data: response.data, success: 1 };
  } catch (error) {
    console.error(error);
    return { data: [], success: 0 }; // Return an empty array instead of null
  }
};

export const getAccountsList = async (): Promise<{
  data: AccountList[];
  success: number;
}> => {
  try {
    const headers = await getHeadersForRequest();
    const response = await axios.get<AccountList[]>(
      `${process.env.REACT_APP_TEST_API_URL}/accounts/getall`,
      { headers }
    );
    return { data: response.data, success: 1 };
  } catch (error) {
    console.error(error);
    return { data: [], success: 0 }; // Return an empty array instead of null
  }
};

export const getProductList = async () => {
  const headers = await getHeadersForRequest();
  try {
    const response = await axios.get<ProductList[]>(
      `${process.env.REACT_APP_TEST_API_URL}/products/getAll`,
      { headers }
    );
    return { data: response.data, success: 1 };
  } catch (error) {
    console.error(error);
    return { data: [], success: 0 };
  }
};
