import { Button, Input, Select, Stack, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { getHeadersForRequest } from "../utils/functions";
import { CustomerTypes } from "../utils/strings";
import useCustomTranslation from "../hooks/useCustomTranslation";

type AddCustomerCardProps = {
  id?: string;
  name?: string;
  tin?: string;
  phoneNumber?: string;
  location?: string;
  isVoid?: boolean;
  type?: number;
  isEdit?: boolean;
};

export const AddCustomer: React.FC<AddCustomerCardProps> = ({
  name,
  tin,
  phoneNumber,
  location,
  id,
  isVoid,
  type,
  isEdit,
}) => {
  const [name1, setName] = useState<string>(name || "");
  const [tin1, setTin] = useState<string>(tin || "");
  const [phoneNumber1, setPhoneNumber] = useState<string>(phoneNumber || "");
  const [location1, setLocation] = useState<string>(location || "");
  const [isVoid1, setisVoid] = useState<boolean>(isVoid || false);
  const [type1, setType] = useState<number>(type || 0);
  const [selectedTypeString, setSelectedTypeString] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t, changeLanguage } = useCustomTranslation();
  const toast = useToast();

  const handleEdit = async () => {
    setIsLoading(true);
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "warning",
      });
      setIsLoading(false);
      return;
    } else {
      const headers = await getHeadersForRequest();

      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/customers/update`,
          {
            id: id,
            name: name1,
            tin: tin1 === "" ? null : tin1,
            phoneNumber: phoneNumber1,
            location: location1,
            category: type1,
            isVoid: isVoid1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setTin("");
          setPhoneNumber("");
          setLocation("");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "error",
      });
      setIsLoading(false);
    } else {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/customers/save`,
          {
            name: name1,
            tin: tin1 === "" ? null : tin1,
            phoneNumber: phoneNumber1,
            location: location1,
            category: type1,
          },
          { headers }
        )
        .then((response) => {
          // console.log(response.data);
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setTin("");
          setPhoneNumber("");
          setLocation("");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };
  return (
    <Stack>
      <Text>{t("Name")}</Text>
      <Input
        defaultValue={name1}
        onChange={(e) => setName(e.target.value)}
        placeholder={t("Enter name")}
        autoCapitalize={"words"}
      />

      <br />
      <br />

      <Text>{t("Customer ID type")}</Text>
      <Select
        minW={"300"}
        flex={1}
        onChange={(e) => {
          const values = e.target.value.split("-");
          setType(parseInt(values[0]));
          setSelectedTypeString(values[1]);
        }}
      >
        <option value={0}>{t("Pick ID Type")}</option>
        {CustomerTypes.map((types) => (
          <option value={types.id + "-" + types.description}>
            {types.description}
          </option>
        ))}
      </Select>

      <br />
      <br />

      {type1 !== 6 && type1 !== 0 && (
        <>
          <Text>{selectedTypeString}</Text>
          <Input
            defaultValue={tin1}
            onChange={(e) => setTin(e.target.value)}
            placeholder={t("Enter") + selectedTypeString}
            inputMode={"numeric"}
          />

          <br />
          <br />
        </>
      )}

      <Text>{t("Phone Number")}</Text>
      <Input
        defaultValue={phoneNumber1}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder={t("Enter phone number")}
        inputMode="tel"
      />

      <br />
      <br />

      <Text>{t("Location")}</Text>
      <Input
        defaultValue={location1}
        onChange={(e) => setLocation(e.target.value)}
        placeholder={t("Enter location")}
      />

      <br />
      <br />

      <Button onClick={isEdit ? handleEdit : handleSave} isLoading={isLoading}>
        <Text>{isEdit ? t("Edit") : t("Save")}</Text>
      </Button>
    </Stack>
  );
};
