import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { AccountList } from "../utils/types";
import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { AddAccount } from "../components/AddAccount";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";

const AccountsScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [accountDetails, setAccountDetails] = useState<AccountList[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<AccountList[]>([]);
  const [searchTerm, setSearchTerm] = useState<String>("");
  const [totalOwed, setTotalOwed] = useState<number>(0);
  const [count, setCount] = useState(0);

  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    setIsLoading(true);
    getAllAccounts();
  }, [isAddMode, isEditMode]);

  useEffect(() => {
    setIsLoading(true);
    filterCustomers();
  }, [searchTerm, accounts]);

  const getAllAccounts = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<[]>(
        `${process.env.REACT_APP_TEST_API_URL}/accounts/getall`,
        { headers }
      );
      setTotalOwed(
        response.data.reduce(
          (total, account: any) => total + Number(account.balance),
          0
        )
      );
      console.log(response.data);
      setAccounts(response.data);
      setCount(1);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const filterCustomers = () => {
    if (!searchTerm) {
      setFilteredCustomers(accounts);
      if (count === 1) {
        setIsLoading(false);
      }
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData =
        accounts !== null
          ? accounts.filter((accounts) =>
              Object.keys(accounts).some((key: any) => {
                const value = accounts[key as keyof AccountList];
                return (
                  value !== null &&
                  value.toString().toLowerCase().includes(lowercasedFilter)
                );
              })
            )
          : [];
      setFilteredCustomers(filteredData);
      setIsLoading(false);
    }
  };

  const loadAccountDetails = async (id: number) => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/accounts/getdetails`,
        { id: id },
        { headers }
      );

      setModalIsLoading(false);
      setAccountDetails(response.data);
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav pageName={"Accounts"}/>
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <InputGroup maxW={500}>
                  <Input
                    placeholder={t("Search for an account...")}
                    width="100%"
                    borderRadius="4"
                    py="3"
                    px="1"
                    fontSize="14"
                    onChange={(text) => setSearchTerm(text.target.value)}
                    autoCapitalize="none"
                  />
                  <InputRightAddon>{t("Search")}</InputRightAddon>
                </InputGroup>

                <Text
                  fontSize={20}
                  textAlign={"center"}
                  color={totalOwed <= 0 ? "red" : "unset"}
                  fontWeight={"bold"}
                >
                  {t("Total balance:")} {numberWithCommas(totalOwed)}/=
                </Text>

                <Button
                  onClick={() => {
                    setIsEditMode(false);
                    setIsAddMode(true);
                  }}
                >
                  {t("Add Account")}
                </Button>
              </HStack>

              <Stack h={10} />

              {filteredCustomers.length > 0 ? (
                <TableContainer>
                  <Table variant="striped">
                    <TableCaption>{t("List of accounts")}</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>{t("Account Name")}</Th>
                        <Th>{t("Overdraft Allowed")}</Th>
                        <Th isNumeric>{t("Current Balance")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredCustomers.map((account: any, index: number) => (
                        <Tr
                          onClick={() => {
                            setShowDetailsModal(true);
                            const id = parseInt(account.id);
                            loadAccountDetails(id);
                          }}
                        >
                          <Td>{account.id} </Td>
                          <Td>{account.name}</Td>
                          {/* <Td>{account.balance}</Td> */}
                          <Td>{account.limitCheck ? t("YES") : t("NO")}</Td>
                          <Td
                            color={account.balance <= 0 ? "red" : "unset"}
                            isNumeric
                          >
                            {numberWithCommas(account.balance)}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>ID</Th>
                        <Th>{t("Account Name")}</Th>
                        <Th>{t("Overdraft Allowed")}</Th>
                        <Th isNumeric>{t("Current Balance")}</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              ) : (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    {t("There are no Accounts")}
                  </Text>
                </Stack>
              )}
            </Stack>
          )}

          {/* CUSTOMER DETAILS MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Account Details")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  accountDetails.length > 0 && (
                    <>
                      <Text>
                        {t("Name:")}
                        {accountDetails[0].name}
                      </Text>
                      <Text>
                        {t("Overdraft Control:")}{" "}
                        {accountDetails[0].limitCheck.toString()}
                      </Text>
                      <Text>
                        {t("Overdraft Limit:")}{" "}
                        {numberWithCommas(accountDetails[0].limit)}
                      </Text>

                      <br />

                      <Button
                        disabled={isLoading}
                        onClick={() => {
                          navigate(
                            `/AccountStatement/${accountDetails[0].id}/${accountDetails[0].name}`
                          );
                        }}
                      >
                        {t("View Statement")}
                      </Button>
                    </>
                  )
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowDetailsModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      setShowDetailsModal(false);
                      setIsEditMode(true);
                      setIsAddMode(false);
                      setShowDetailsModal(false);
                    }}
                  >
                    {t("Edit")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* ADD CUSTOMER MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isAddMode}
            onClose={() => setIsAddMode(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Add Account")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  <Stack flex={1}>
                    <Stack alignItems={"center"}>
                      <Stack>
                        <AddAccount
                          name={""}
                          id={""}
                          limit={""}
                          limitCheck={false}
                          isEdit={isEditMode}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* EDIT CUSTOMER MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isEditMode}
            onClose={() => setIsEditMode(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Account Details")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  isEditMode && (
                    <Stack flex={1}>
                      <Stack alignItems={"center"}>
                        <Stack>
                          <AddAccount
                            name={accountDetails ? accountDetails[0].name : ""}
                            id={
                              accountDetails
                                ? accountDetails[0].id.toString()
                                : ""
                            }
                            limit={
                              accountDetails
                                ? accountDetails[0].limit.toString()
                                : ""
                            }
                            limitCheck={
                              accountDetails
                                ? accountDetails[0].limitCheck
                                : false
                            }
                            isEdit={isEditMode}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AccountsScreen;
