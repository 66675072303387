import { useEffect, useState } from "react";
import { PaymentsList } from "../utils/types";
import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import { PaymentCard } from "../components/PaymentCard";
import { FaTrash } from "react-icons/fa";
import Nav from "../components/navbar";

const RecentPaymentsScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [transactions, setTransactions] = useState<PaymentsList[]>([]);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [selectedTransaction, setSelectedTransaction] = useState<number>(0);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
    getAllTransactions();
  }, []);

  const getAllTransactions = async () => {
    try {
      const today = new Date();
      today.setDate(today.getDate() - 30);
      const startDate = today;
      const endDate = new Date();

      const oldHeaders = await getHeadersForRequest();
      const newHeaders = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      const headers = { ...oldHeaders, ...newHeaders };

      const response = await axios.get<PaymentsList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/payments/getAll`,
        {
          headers,
        }
      );

      console.log(response.data);

      const filteredOrdersPaymentPending = await response.data.filter(
        (transaction) => transaction.isVoid === false
      );

      setTransactions(
        filteredOrdersPaymentPending.sort(
          (a, b) =>
            new Date(b.paymentDate).getTime() -
            new Date(a.paymentDate).getTime()
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const groupedTransactions: {
    date: string;
    transactions: PaymentsList[];
  }[] = [];

  // Sort the groupedTransactions array in descending order of transaction date
  groupedTransactions.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateB - dateA;
  });

  for (let transaction of transactions) {
    const date = new Date(transaction.paymentDate).toLocaleDateString();
    const existingGroup = groupedTransactions.find(
      (group) => group.date === date
    );
    if (existingGroup) {
      existingGroup.transactions.push(transaction);
    } else {
      groupedTransactions.push({ date, transactions: [transaction] });
    }
  }

  const handleTransactionClick = (transaction: number) => {
    setShowConfirmationModal(true);
    setSelectedTransaction(transaction);
  };

  const voidTransaction = async () => {
    setIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/payments/delete`,
          {
            transactionId: selectedTransaction,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          setIsLoading(false);
          toast({
            description: "Transaction VOID successful",
            duration: 3000,
            status: "success",
          });
          setShowConfirmationModal(false);
          getAllTransactions();
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            description: "Error, please try again later",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {
      // toast.show({ description: error.toString() });
      console.error(error);
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Payment History"}/>
      </Stack>
      <Stack flex={1}>
        <Stack pt={2} pb={10} px={3}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <>
              <Text>Record count: {groupedTransactions.length}</Text>
              {groupedTransactions.map((groupedTransaction, index) => (
                <Stack key={index}>
                  <Heading size="md" my={2}></Heading>
                  {groupedTransaction.transactions.map((transaction, index) => (
                    <>
                      <Stack
                        px={5}
                        key={index}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          if (transaction.isVoid) {
                            toast({
                              title: "Cannot edit a VOID receipt",
                            });
                          } else {
                            handleTransactionClick(transaction.id);
                          }
                        }}
                      >
                        <PaymentCard payment={transaction} />
                      </Stack>
                    </>
                  ))}
                </Stack>
              ))}
              {groupedTransactions.length < 1 && (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    There are no Transactions to show
                  </Text>
                  <Text fontSize={20} textAlign={"center"}>
                    Go to the Sales screen to record your first transaction!
                  </Text>
                </Stack>
              )}
            </>
          )}

          {/* CONFIRMATION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Alert</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text textAlign={"center"}>
                    Are you sure you want to VOID this receipt?
                  </Text>
                  <Text textAlign={"center"}>THIS ACTION IS IRREVERSIBLE!</Text>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    isLoading={isLoading}
                    bg={"red"}
                    onClick={() => {
                      voidTransaction();
                    }}
                  >
                    Yes
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecentPaymentsScreen;
