import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import Nav from "../components/navbar";
import { AccountList } from "../utils/types";
import { getAccountsList } from "../utils/API_calls";
import useCustomTranslation from "../hooks/useCustomTranslation";

const AccountTransferExternalScreen = () => {
  const [notes, setNotes] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [accountTo, setAccountTo] = useState("");
  const [accountFrom, setAccountFrom] = useState("");
  const [amount, setAmount] = useState<number>(0);

  const { t, changeLanguage } = useCustomTranslation();

  const today = new Date();
  today.setDate(today.getDate());

  const [date, setDate] = useState<string>(today.toISOString());

  const toast = useToast();

  useEffect(() => {
    retrieveAccounts();
  }, []);

  const retrieveAccounts = async () => {
    setIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setIsLoading(false);
  };

  const handleSaveData = async () => {
    if (accountTo === "") {
      toast({
        title: t("Please pick an account"),
        status: "error",
      });
      return;
    }

    if (amount === 0) {
      toast({
        title: t("Please fill in the amount"),
        status: "error",
      });
      return;
    }

    if (notes === "") {
      toast({
        title: t("Please enter source of funds"),
        status: "error",
      });
      return;
    }

    const data = {
      accountTo: accountTo,
      amount: amount,
      date: date,
      notes: notes,
    };

    setIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/accounts/externalTransfer`,
        data,
        { headers }
      );
      console.log(response.data);
      setIsLoading(false);
      toast({
        title: t("Transfer Details Saved!"),
        status: "success",
      });
      await retrieveAccounts();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      await retrieveAccounts();
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"External Transfer"}/>
      </Stack>
      {isLoading ? (
        <Center h={600}>
          <Loading />
        </Center>
      ) : (
        <Stack>
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            p={6}
            m="10px auto"
            as="form"
          >
            <Stack alignItems={"center"} pb={10} flex={1}>
              <Text fontSize="lg" fontFamily="">
                {t("Amount")}
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                    inputMode="numeric"
                  />
                </Stack>
              </Stack>

              <Stack width={"300"}>
                <Text fontSize="lg" fontFamily="">
                  {t("Account")}
                </Text>
                <Select
                  onChange={(e) => setAccountTo(e.target.value)}
                  minW={"300"}
                >
                  <option value={""}>{t("Pick Account")}</option>
                  {accounts.map((account) => (
                    <option value={account.id.toString()}>
                      {account.name}
                    </option>
                  ))}
                </Select>
              </Stack>

              <Text fontSize="lg" fontFamily="">
                {t("Source")}
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setNotes(e.target.value)}
                    inputMode="numeric"
                  />
                </Stack>
              </Stack>

              <Text fontSize="lg" fontFamily="">
                {t("Date")}
              </Text>

              <Stack>
                <Input
                  minW={"300"}
                  placeholder="Start Date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Stack>
            </Stack>

            <Stack alignItems={"center"}>
              <Button
                variant={"solid"}
                onClick={() => handleSaveData()}
                borderRadius={20}
                width={200}
              >
                <Text>{t("Save")}</Text>
              </Button>
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default AccountTransferExternalScreen;
