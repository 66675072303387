import { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Input,
  Select,
  Text,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
  Checkbox,
  useToast,
  ModalOverlay,
  IconButton,
  Center,
} from "@chakra-ui/react";

import {
  getDescriptionById,
  getHeadersForRequest,
  numberWithCommas,
} from "../utils/functions";
import axios from "axios";
import Cookies from "js-cookie";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import {
  AccountList,
  CustomerList,
  ProductList,
  SaleListItemGeneral,
  SupplierList,
} from "../utils/types";
import {
  getAccountsList,
  getCustomerList,
  getProductList,
  getSupplierList,
} from "../utils/API_calls";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import useCustomTranslation from "../hooks/useCustomTranslation";

const Form1 = () => {
  const [listItems, setListItems] = useState<SaleListItemGeneral[]>([]);
  const [price, setPrice] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [productId, setProductId] = useState<string>("");
  const [pktNum, setPacketNumber] = useState<string>("");
  const [taxRate, setTaxRate] = useState<string>("");
  const [taxAmount, setTaxAmount] = useState<number>(0);
  const [taxAmountTotal, setTaxAmountTotal] = useState<number>(0);
  const [priceIsInclusive, setPriceIsInclusive] = useState<boolean>(true);
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [prompt, setPrompt] = useState<boolean>(false);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [receiptTotal, setReceiptTotal] = useState<number>(0);
  const [buyerIsSupplier, setBuyerIsSupplier] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [suppliers, setSuppliers] = useState<SupplierList[]>([]);
  const [products, setProducts] = useState<ProductList[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showTRAConfirmationModal, setShowTRAConfirmationModal] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [receivedTransactionNumber, setReceivedTransactionNumber] =
    useState<number>(0);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("0");

  const { t, changeLanguage } = useCustomTranslation();

  const toast = useToast();

  useEffect(() => {
    retrieveProducts();
  }, []);

  useEffect(() => {
    calculateTaxAmount();
  }, [price, priceIsInclusive, product, taxRate]);

  useEffect(() => {
    calculateTaxAmountTotal();
  }, [price, priceIsInclusive, product, taxRate, pktNum]);

  const handleAddItem = () => {
    // console.log(price, taxRate, product);
    if (
      price === "" ||
      parseInt(price) <= 0 ||
      product === "" ||
      pktNum === "" ||
      parseInt(pktNum) <= 0
    ) {
      toast({
        title: t("Please fill all fields"),
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else {
      let newItem: SaleListItemGeneral;
      let packetNumber = parseInt(pktNum);
      let tax = parseInt(taxRate);
      let price1: number =
        taxRate === "1" && !priceIsInclusive
          ? parseInt(price) * 1.18
          : parseInt(price);

      newItem = {
        id: Date.now(),
        productId,
        price1,
        product,
        packetNumber,
        tax,
      };

      // Check if the combination of product, packet size, and flavour has been added before
      const isDuplicate = listItems.some((item) => item.product === product);

      if (isDuplicate) {
        toast({
          title: t("This combination already exists"),
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      setListItems([...listItems, newItem]);
      toast({
        title: t("Added to the list!"),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setPrice("");
      setPacketNumber("");
    }
  };

  const handleClearList = () => {
    setListItems([]);
  };

  const retrieveCustomers = async () => {
    setModalIsLoading(true);
    const customers = await getCustomerList();
    if (customers.success === 1) {
      setCustomers(customers.data);
    } else {
      setCustomers([]);
    }
    setModalIsLoading(false);
  };

  const retrieveSuppliers = async () => {
    setModalIsLoading(true);
    const suppliers = await getSupplierList();
    if (suppliers.success === 1) {
      setSuppliers(suppliers.data);
    } else {
      setSuppliers([]);
    }
    setModalIsLoading(false);
  };

  const retrieveProducts = async () => {
    setModalIsLoading(true);
    const products = await getProductList();
    if (products.success === 1) {
      setProducts(products.data);
    } else {
      setProducts([]);
    }
    setModalIsLoading(false);
  };

  const retrieveAccounts = async () => {
    setModalIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setModalIsLoading(false);
  };

  const handleProductSelection = (product: string) => {
    const split = product.split("-");
    setProduct(split[0]);
    setTaxRate(split[1]);
    setProductId(split[2]);
  };

  const calculateTaxAmount = () => {
    if (taxRate === "1") {
      if (priceIsInclusive) {
        const tax = (parseInt(price) / 1.18) * 0.18;
        setTaxAmount(Math.round(tax * 100) / 100);
      } else {
        const tax = parseInt(price) * 0.18;
        setTaxAmount(Math.round(tax * 100) / 100);
      }
    }
  };

  const calculateTaxAmountTotal = () => {
    if (taxRate === "1") {
      if (priceIsInclusive) {
        const tax = (parseInt(price) / 1.18) * 0.18 * parseInt(pktNum);
        setTaxAmountTotal(Math.round(tax * 100) / 100);
      } else {
        const tax = parseInt(price) * 0.18 * parseInt(pktNum);
        setTaxAmountTotal(Math.round(tax * 100) / 100);
      }
    }
  };

  const getTotal = () => {
    const receiptTotal = listItems.reduce((acc, item) => {
      const price = Number(item.price1);
      const packetNumber = item.packetNumber;
      const itemTotal = price * packetNumber;
      return acc + itemTotal;
    }, 0);
    setReceiptTotal(receiptTotal);
  };

  const handleDeleteItem = (index: number) => {
    console.log(index);
    const newItems = [...listItems];
    newItems.splice(index, 1);
    setListItems(newItems);
    setShowConfirmationModal(false);
  };

  const handleSaveData = async () => {
    // Save listItems to database

    // console.log(listItems);

    if (groupValues.includes("paid") && selectedAccount === "0") {
      toast({
        description: t("You need to select a valid account"),
        status: "error",
      });
      return;
    }
    const email = Cookies.get("emailAddress");
    const data = {
      customerId: buyerIsSupplier
        ? null
        : selectedCustomer === ""
        ? null
        : selectedCustomer,
      supplierId: buyerIsSupplier ? selectedSupplier : null,
      paymentStatus: groupValues.includes("paid"),
      paymentDate: groupValues.includes("paid") ? new Date() : null,
      userId: email,
      deliveryStatus: groupValues.includes("delivered"),
      deliveryDate: groupValues.includes("delivered") ? new Date() : null,
      transactionDate: new Date(),
      physicalDeliveryNote: groupValues.includes("physical"),
      totalAmount: receiptTotal,
      items: listItems,
      invoiceDate: invoiceDate,
      transactionType: 1,
      deliveryNoteNumber: groupValues.includes("physical")
        ? deliveryNoteNumber
        : "",
      traReceiptNumber: "",
      traReceiptTime: "",
      account: groupValues.includes("paid") ? selectedAccount : "0",
    };

    console.log(JSON.stringify(data));

    if (data.supplierId === "") {
      toast({ description: t("Invalid Supplier"), status: "error" });
      return;
    }

    if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
      toast({
        description: t("Invalid Delivery Note Number"),
        status: "error",
      });
      return;
    }

    setIsLoading(true);

    try {
      const headers = await getHeadersForRequest();

      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/save/sale/general`,
        data,
        { headers }
      );
      console.log(response.data);
      if (response.data.success) {
        setIsLoading(false);
        setShowModal(false);
        setShowTRAConfirmationModal(true);
        setReceivedTransactionNumber(response.data.transactionNumber);
        toast({
          description: t("Transaction Details Saved!"),
          status: "success",
        });
      } else {
        setIsLoading(false);
        toast({
          description: t("FailedToSaveTransactionDetails"),
          status: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleTRApost = async () => {
    if (receivedTransactionNumber === 0) {
      toast({
        description: t("FailedToSaveUploadDetails"),
        status: "error",
      });
      return;
    } else {
      setIsLoading(true);

      try {
        const headers = await getHeadersForRequest();

        const response = await axios.post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/save/traPost`,
          { transactionId: receivedTransactionNumber },
          { headers }
        );
        if (response.data.success) {
          toast({
            title: t("SuccessfullyUploadedDetailsToTRA"),
            status: "success",
          });
          setIsLoading(false);
        } else {
          toast({
            title: response.data.message,
            status: "error",
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  const handlePromptClose = () => {
    setPrompt(false);
    retrieveCustomers();
    retrieveSuppliers();
    retrieveAccounts();
  };

  const handleInputChange = () => {
    setPriceIsInclusive(!priceIsInclusive);
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        {t("RecordOrder")}
      </Heading>
      <Stack px={5} alignItems={"center"} pb={10}>
        <Stack>
          <HStack alignItems={"center"} justifyContent={"center"}>
            <Text color="grey" fontSize="lg" fontFamily="">
              {t("Price")}
            </Text>

            <Button
              onClick={() => {
                handleInputChange();
              }}
            >
              {priceIsInclusive ? "Inclusive" : "Exclusive"}
            </Button>
          </HStack>
          <Stack borderRadius={30} width={300}>
            <Input
              borderWidth={3}
              fontSize="md"
              pl={5}
              value={price}
              inputMode="numeric"
              backgroundColor="transparent"
              variant={"underlined"}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              placeholder={"Enter Price"}
            />
          </Stack>
        </Stack>
        <br />
        <Stack>
          <Text color="grey" fontSize="lg" fontFamily="">
            {t("Product")}
          </Text>
          <Stack borderRadius={30} width={300}>
            <Select
              onChange={(e) => handleProductSelection(e.target.value)}
              borderRadius={30}
              borderWidth={3}
            >
              <option value={""} key={0}>
                {t("SelectProduct")}
              </option>
              {products.map((value) => (
                <option
                  value={value.name + "-" + value.taxCategory + "-" + value.id}
                  key={value.id}
                >
                  {value.name.toString()}
                </option>
              ))}
            </Select>
          </Stack>
        </Stack>

        <br />

        <Text color="grey" fontSize="lg" fontFamily="">
          {t("Quantity")}
        </Text>

        <Stack width={300}>
          <Input
            fontSize="md"
            pl={5}
            borderRadius={30}
            defaultValue={pktNum}
            inputMode="numeric"
            onChange={(e) => setPacketNumber(e.target.value)}
            value={pktNum}
            borderWidth={3}
          />
        </Stack>

        <br />

        <HStack>
          <Stack>
            <Text color="grey" fontSize="lg" fontFamily="">
              {t("TaxPerItem")}
            </Text>

            <Stack width={300}>
              <Input
                fontSize="md"
                pl={5}
                borderRadius={30}
                defaultValue={taxAmount.toString()}
                value={taxAmount.toString()}
                borderWidth={3}
                isDisabled
              />
            </Stack>
          </Stack>

          <Stack>
            <Text color="grey" fontSize="lg" fontFamily="">
              {t("TotalTaxAmount")}
            </Text>

            <Stack width={300}>
              <Input
                fontSize="md"
                pl={5}
                borderRadius={30}
                defaultValue={numberWithCommas(taxAmountTotal).toString()}
                value={numberWithCommas(taxAmountTotal).toString()}
                borderWidth={3}
                isDisabled
              />
            </Stack>
          </Stack>
        </HStack>
      </Stack>

      <Stack alignItems={"center"}>
        <Button
          variant={"solid"}
          onClick={handleAddItem}
          borderRadius={20}
          width={200}
          colorScheme="blue"
        >
          <Text>{t("Calculate")}</Text>
        </Button>
      </Stack>

      {listItems.length > 0 && (
        <Stack p={10}>
          <TableContainer>
            <Table variant="simple">
              <TableCaption>
                {t("NumberOfItems")} {listItems.length}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>{t("QTY")}</Th>
                  <Th>{t("DESCRIPTION")}</Th>
                  <Th>{t("TAXRATE")}</Th>
                  <Th isNumeric>{t("AMOUNT(InclTax)")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listItems.map((items, key) => (
                  <Tr
                    key={key}
                    onClick={() => {
                      setSelectedItem(key);
                      setShowConfirmationModal(true);
                    }}
                  >
                    <Td>{items.packetNumber}</Td>
                    <Td>{items.product}</Td>
                    <Td>{getDescriptionById(items.tax)}</Td>
                    <Td isNumeric>
                      {numberWithCommas(
                        (Math.round(items.price1 * items.packetNumber) * 100) /
                          100
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack
            flex={1}
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={10}
            py={5}
          >
            <Button colorScheme="red" onClick={handleClearList}>
              {t("CLEAR")}
            </Button>

            <Button
              colorScheme="green"
              onClick={() => {
                setShowModal(true);
                setPrompt(true);
                getTotal();
              }}
            >
              {t("SAVE")}
            </Button>
          </Stack>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("DeleteItem")}</ModalHeader>
              <ModalBody>{t("ConfirmDeleteItem")}</ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    colorScheme="red"
                    isLoading={isLoading}
                    onClick={() => {
                      handleDeleteItem(selectedItem);
                    }}
                  >
                    {t("Delete")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("SaveOrder")}</ModalHeader>
              <ModalBody>
                {prompt ? (
                  <>
                    <Text>
                      {t("YourTotalIs")} {numberWithCommas(receiptTotal)}/=
                    </Text>
                    <Text>{t("ConfirmSaveOrder")}</Text>
                  </>
                ) : modalIsLoading ? (
                  <>
                    <Center>
                      <Loading />
                    </Center>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setBuyerIsSupplier(!buyerIsSupplier);
                      }}
                    >
                      {buyerIsSupplier
                        ? t("SellingSupplier")
                        : t("SellingCustomer")}
                    </Button>
                    {buyerIsSupplier ? (
                      <>
                        <Text>{t("PickSupplier")} </Text>
                        <Select
                          // selectedValue={selectedSupplier}
                          onChange={(e) => setSelectedSupplier(e.target.value)}
                        >
                          {suppliers.map((supplier) => (
                            <option value={supplier.id.toString()}>
                              {supplier.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <>
                        <Text>{t("PickCustomer")}</Text>

                        <HStack justifyContent={"space-between"}>
                          <Select
                            flex={1}
                            onChange={(e) =>
                              setSelectedCustomer(e.target.value)
                            }
                          >
                            <option value={""}>{t("Cash")}</option>

                            {customers.map((customer) => (
                              <option value={customer.id.toString()}>
                                {customer.name}
                              </option>
                            ))}
                          </Select>
                          {/* <Stack pl={1}>
                            <Button
                            >
                              Add
                            </Button>
                          </Stack> */}
                        </HStack>
                      </>
                    )}

                    <Text>{t("Orderdetails")}</Text>
                    <Stack>
                      <CheckboxGroup
                        onChange={(values: string[]) => setGroupValues(values)}
                        value={groupValues}
                      >
                        <Checkbox value="paid" my={2}>
                          {t("Paid")}
                        </Checkbox>
                        <Checkbox value="delivered" my={2}>
                          {t("Delivered")}
                        </Checkbox>
                        <Checkbox value="physical" isChecked my={2}>
                          {t("PhysicalDeliveryNote")}
                        </Checkbox>
                        {/* <Checkbox value="tra" isChecked my={2}>
                          {t("TRAReceipt")}
                        </Checkbox> */}
                      </CheckboxGroup>
                    </Stack>

                    <br />
                    {groupValues.includes("paid") && (
                      <>
                        <Text>Pick the account used for payment </Text>
                        <Select
                          onChange={(e) => setSelectedAccount(e.target.value)}
                        >
                          <option value={""}>{t("Pick Account")}</option>
                          {accounts.map((account) => (
                            <option
                              // label={customer.name}
                              value={account.id.toString()}
                            >
                              {account.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    )}
                    <br />

                    <Stack>
                      {groupValues.includes("physical") && (
                        <Input
                          borderWidth={3}
                          fontSize="md"
                          pl={5}
                          mb={2}
                          backgroundColor="transparent"
                          variant={"underlined"}
                          onChange={(e) =>
                            setDeliveryNoteNumber(e.target.value)
                          }
                          placeholder={"Enter delivery note number"}
                          inputMode="numeric"
                        />
                      )}
                    </Stack>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    isLoading={isLoading}
                    onClick={() => {
                      prompt ? handlePromptClose() : handleSaveData();
                    }}
                  >
                    {t("Save")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showTRAConfirmationModal}
            onClose={() => setShowTRAConfirmationModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Fiscalize")}</ModalHeader>
              <ModalBody>{t("PostToTRA?")}</ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowTRAConfirmationModal(false);
                      handleClearList();
                    }}
                  >
                    {t("No")}
                  </Button>
                  <Button
                    colorScheme="green"
                    isLoading={isLoading}
                    onClick={() => {
                      handleTRApost();
                    }}
                  >
                    {t("Yes")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      )}
    </>
  );
};

export default function SalesOrderGeneral() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={6}
        minW={"70%"}
        m="10px auto"
        as="form"
      >
        <HStack justifyContent={"space-between"} alignItems={"baseline"}>
          <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={() => navigate("/dashboard")}
            icon={<FaAngleLeft />}
            aria-label={`Go to home`}
          />

          <Stack alignItems={"flex-end"}>
            <ColorModeSwitcher mb={2} />
          </Stack>
        </HStack>

        <Form1 />
      </Box>
    </>
  );
}
