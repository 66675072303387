// src/hooks/useCustomTranslation.ts
import { useTranslation, UseTranslationResponse } from 'react-i18next';

interface CustomTranslationHook {
  t: UseTranslationResponse<string, string>['t'];
  changeLanguage: (lng: string) => void;
}

const useCustomTranslation = (): CustomTranslationHook => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return {
    t,
    changeLanguage,
  };
};

export default useCustomTranslation;
