import { Stack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";

type DataPoint = {
  primary: string; // Ensure primary is a string (product names)
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type ApiResponse = Record<string, { quantity: number; revenue: number; size: number }>;

export default function BarTemplateProductQty({ initialData }: { initialData: ApiResponse }) {
  const [data, setData] = useState<Series[]>([]);
  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    const processData = (data: ApiResponse): Series[] => {
      const quantities: DataPoint[] = [];

      Object.keys(data).forEach((key) => {
        quantities.push({
          primary: key,
          secondary:  data[key].size
        });
      });

      return [
        { label: t("Quantity (in Grams)"), data: quantities },
      ];
    };

    const processedData = processData(initialData);
    setData(processedData);
  }, [initialData, t]);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band",
      position: "left",
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear",
        position: "bottom",
      },
    ],
    []
  );

  return (
    <Stack w="100%" h={500}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          dark: useColorModeValue(false, true),
        }}
      />
    </Stack>
  );
}