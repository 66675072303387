import { Button, Input, Stack, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import useCustomTranslation from "../hooks/useCustomTranslation";

interface AddSupplierCardProps {
  id: string;
  name: string;
  tin: string;
  phoneNumber: string;
  location: string;
  isEdit: boolean;
}

export const AddSupplier: React.FC<AddSupplierCardProps> = ({
  name,
  tin,
  phoneNumber,
  location,
  id,
  isEdit,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name1, setName] = useState<string>(name);
  const [tin1, setTin] = useState<string>(tin);
  const [phoneNumber1, setPhoneNumber] = useState<string>(phoneNumber);
  const [location1, setLocation] = useState<string>(location);

  const toast = useToast();
  const { t, changeLanguage } = useCustomTranslation();

  const handleEdit = async () => {
    setIsLoading(true);
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "warning",
      });
      setIsLoading(false);
      return;
    } else {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/suppliers/update`,
          {
            id: id,
            name: name1,
            tin: tin1,
            phoneNumber: phoneNumber1,
            location: location1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Saved successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setTin("");
          setPhoneNumber("");
          setLocation("");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "warning",
          });
        });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "warning",
      });
      setIsLoading(false);
      return;
    } else {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/suppliers/save`,
          {
            name: name1,
            tin: tin1,
            phoneNumber: phoneNumber1,
            location: location1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Saved successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setTin("");
          setPhoneNumber("");
          setLocation("");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };
  return (
    <Stack>
      <Text>{t("Name")}</Text>
      <Input
        defaultValue={name1}
        onChange={(e) => setName(e.target.value)}
        placeholder={t("Enter name")}
        autoCapitalize={"words"}
      />

      <br />

      <Text>TIN Number</Text>
      <Input
        defaultValue={tin1}
        onChange={(e) => setTin(e.target.value)}
        placeholder="Enter TIN number"
        inputMode={"numeric"}
      />

      <br />

      <Text>{t("Phone Number")}</Text>
      <Input
        defaultValue={phoneNumber1}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder={t("Enter phone number")}
        inputMode="tel"
      />

      <br />

      <Text>{t("Location")}</Text>
      <Input
        defaultValue={location1}
        onChange={(e) => setLocation(e.target.value)}
        placeholder={t("Enter location")}
      />

      <br />

      <Button onClick={isEdit ? handleEdit : handleSave} isLoading={isLoading}>
        <Text>{isEdit ? t("Edit") : t("Save")}</Text>
      </Button>
    </Stack>
  );
};
