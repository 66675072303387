import { Center, Spinner, Stack, Text } from "@chakra-ui/react";
import useCustomTranslation from "../hooks/useCustomTranslation";

export const Loading = () => {
  
  const { t, changeLanguage } = useCustomTranslation();
  return (
    <Center h={"60%"}>
      <Stack alignItems={"center"} justifyContent={"center"}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />

        <Text>{t("Loading")}</Text>
      </Stack>
    </Center>
  );
};
