import { Stack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";

type DataPoint = {
  primary: string; // Month names
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type CustomerPurchasesResponse = {
  customerPurchases: { [key: string]: number[] };
};

export default function CustomerPurchasesChart() {
  const [data, setData] = useState<Series[]>([]);

  useEffect(() => {
    const fetchCustomerPurchases = async () => {
      try {
        const headers = await getHeadersForRequest();
        const response = await axios.get<CustomerPurchasesResponse>(
          `${process.env.REACT_APP_TEST_API_URL}/analytics/monthly/customer-purchases`,
          {
            headers,
          }
        );
        // console.log("Customer Purchases API Response:", response.data);
        const processedData = processCustomerPurchases(response.data);
        // console.log("Processed Customer Purchases Data:", processedData);
        setData(processedData);
      } catch (error) {
        console.error("Error fetching customer purchases data:", error);
      }
    };

    fetchCustomerPurchases();
  }, []);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band", // Set the scale type explicitly
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear", // Set the scale type explicitly
      },
    ],
    []
  );

  const { t, changeLanguage } = useCustomTranslation();

  const processCustomerPurchases = (
    data: CustomerPurchasesResponse
  ): Series[] => {
    const months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];
    const series: Series[] = [];

    for (const [customerKey, monthlyData] of Object.entries(
      data.customerPurchases
    )) {
      const [customerId, customerName] = customerKey.split(":");
      series.push({
        label: `${t("PurchasesBy")} ${customerName}`,
        data: monthlyData.map((value, index) => ({
          primary: months[index],
          secondary: value,
        })),
      });
    }

    return series;
  };

  return (
    <Stack w="100%" h={500}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          dark: useColorModeValue(false, true),
        }}
      />
    </Stack>
  );
}
