import {
  Button,
  Checkbox,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { getHeadersForRequest } from "../utils/functions";
import useCustomTranslation from "../hooks/useCustomTranslation";

type AddAccountCardProps = {
  id?: string;
  name?: string;
  limitCheck?: boolean;
  limit?: string;
  isEdit?: boolean;
  openingBalance?: number;
};

export const AddAccount: React.FC<AddAccountCardProps> = ({
  name,
  limitCheck,
  limit,
  id,
  openingBalance,
  isEdit,
}) => {
  const [name1, setName] = useState<string>(name || "");
  const [limitCheck1, setLimitCheck1] = useState<boolean>(limitCheck || false);
  const [limit1, setLimit1] = useState<string>(limit || "");
  const [openingBalance1, setOpeningBalance1] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();

  const { t, changeLanguage } = useCustomTranslation();

  const handleEdit = async () => {
    setIsLoading(true);
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "warning",
      });
      setIsLoading(false);
    } else {
      const headers = await getHeadersForRequest();

      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/accounts/update`,
          {
            id: id,
            name: name1,
            limitCheck: limitCheck1,
            limit: limit1 === "" ? null : limit1,
            openingBalance: openingBalance1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setLimit1("");
          setLimitCheck1(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "error",
      });
      setIsLoading(false);
    } else {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/accounts/save`,
          {
            name: name1,
            limitCheck: limitCheck1,
            limit: limit1 === "" ? null : limit1,
            openingBalance: openingBalance1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Saved successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setLimit1("");
          setLimitCheck1(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };

  return (
    <Stack>
      <Text>{t("Name")}</Text>
      <Input
        defaultValue={name1}
        onChange={(e) => setName(e.target.value)}
        placeholder={t("Enter name")}
        autoCapitalize={"words"}
      />

      <br />

      <Text>{t("Opening balance")}</Text>
      <Input
        disabled={isEdit}
        defaultValue={limit1}
        onChange={(e) => setOpeningBalance1(parseInt(e.target.value))}
        placeholder={t("Enter Opening Balance")}
        inputMode="numeric"
      />

      {limitCheck1 && (
        <>
          <br />
          <Text>{t("Overdraft Limit")}</Text>
          <Input
            defaultValue={limit1}
            onChange={(e) => setLimit1(e.target.value)}
            placeholder={t("Enter Overdraft Limit")}
            inputMode="numeric"
          />
        </>
      )}
      <Checkbox
        defaultChecked={limitCheck1}
        onChange={() => {
          setLimitCheck1(!limitCheck1);
          console.log(limitCheck1);
        }}
      >
        {t("Overdraft Allowed")}{" "}
      </Checkbox>

      <br />

      <Button onClick={isEdit ? handleEdit : handleSave} isLoading={isLoading}>
        <Text>{isEdit ? t("Edit") : t("Save")}</Text>
      </Button>
    </Stack>
  );
};
