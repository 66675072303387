import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Loading } from "../components/Loading";
import { AddSupplier } from "../components/AddSupplier";
import Nav from "../components/navbar";
import { SupplierList } from "../utils/types";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";

const SupplierScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [Suppliers, setSuppliers] = useState<SupplierList[]>([]);
  const [SupplierDetails, setSupplierDetails] = useState<SupplierList[]>([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState<SupplierList[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<String>("");
  const [totalOwed, setTotalOwed] = useState<number>(0);
  const [count, setCount] = useState(0);

  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    setIsLoading(true);
    getAllSuppliers();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    filterSuppliers();
  }, [searchTerm, Suppliers]);

  const getAllSuppliers = async () => {
    try {
      const headers = await getHeadersForRequest();

      const response = await axios.get<SupplierList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/payments/supplierBalances`,
        { headers }
      );
      // console.log("Get Suppliers list " + JSON.stringify(response.data));
      setTotalOwed(
        response.data.reduce(
          (total, customer) => total + Number(customer.balance),
          0
        )
      );
      setSuppliers(response.data);
      setCount(1);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const filterSuppliers = () => {
    if (!searchTerm) {
      setFilteredSuppliers(Suppliers);
      if (count === 1) {
        setIsLoading(false);
      }
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData =
        Suppliers !== null
          ? Suppliers.filter((Suppliers) =>
              Object.keys(Suppliers).some((key: any) => {
                const value = Suppliers[key as keyof SupplierList];
                return (
                  value !== null &&
                  value.toString().toLowerCase().includes(lowercasedFilter)
                );
              })
            )
          : [];
      setFilteredSuppliers(filteredData);
      setIsLoading(false);
    }
  };

  const loadSupplierDetails = async (id: number) => {
    setModalIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/suppliers/getdetails`,
        { id: id },
        { headers }
      );
      console.log("RESPONSE: " + JSON.stringify(response.data));
      setModalIsLoading(false);
      setSupplierDetails(response.data);
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  const navigateToOrders = (supplierId: number, supplierName: string) => {
    navigate(`/SupplierOrders/${supplierId}/${supplierName}`);
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav pageName={"Suppliers"}/>
        </Stack>
        <Stack>
          <Stack p={10}>
            {isLoading && (
              <Center h={600}>
                <Loading />
              </Center>
            )}

            {!isLoading && (
              <Stack>
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  <InputGroup maxW={500}>
                    <Input
                      placeholder="Search for a supplier..."
                      width="100%"
                      //   bg="white"
                      borderRadius="4"
                      py="3"
                      px="1"
                      fontSize="14"
                      onChange={(text) => setSearchTerm(text.target.value)}
                      autoCapitalize="none"
                    />
                    <InputRightAddon>Search</InputRightAddon>
                  </InputGroup>

                  <Text fontSize={20} textAlign={"center"}>
                    Total payable: {numberWithCommas(totalOwed)}/=
                  </Text>

                  <Button
                    onClick={() => {
                      setIsEditMode(false);
                      setIsAddMode(true);
                    }}
                  >
                    Add Supplier
                  </Button>
                </HStack>

                <Stack h={10} />

                {filteredSuppliers.length > 0 ? (
                  <TableContainer>
                    <Table variant="striped">
                      <TableCaption>List of suppliers</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>Supplier Name</Th>
                          <Th>TIN</Th>
                          <Th isNumeric>Balance</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredSuppliers.map(
                          (Supplier: any, index: number) => (
                            <Tr
                              onClick={() => {
                                setShowDetailsModal(true);
                                const id = parseInt(Supplier.id);
                                loadSupplierDetails(id);
                                //   setSelectedCustomer(customer);
                              }}
                            >
                              <Td>{Supplier.id} </Td>
                              <Td>{Supplier.name}</Td>
                              <Td>{Supplier.tin}</Td>
                              <Td isNumeric>
                                {numberWithCommas(Supplier.balance)}
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>ID</Th>
                          <Th>Supplier Name</Th>
                          <Th>TIN</Th>
                          <Th isNumeric>Balance</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    h={400}
                  >
                    <Text fontSize={20} textAlign={"center"}>
                      There are no Suppliers
                    </Text>
                    <Text fontSize={20} textAlign={"center"}>
                      Click the + button to add one!
                    </Text>
                  </Stack>
                )}
              </Stack>
            )}

            {/* SUPPLIER DETAILS MODAL */}
            <Modal
              isCentered
              closeOnOverlayClick={false}
              isOpen={showDetailsModal}
              onClose={() => setShowDetailsModal(false)}
            >
              <ModalContent maxWidth="350">
                <ModalCloseButton />
                <ModalHeader>Supplier Details</ModalHeader>
                <ModalBody>
                  {modalIsLoading ? (
                    <Loading />
                  ) : (
                    SupplierDetails.length > 0 && (
                      <>
                        <Text>Name: {SupplierDetails[0].name}</Text>
                        <Text>TIN Number: {SupplierDetails[0].tin}</Text>
                        <Text>
                          Phone Number: {SupplierDetails[0].phoneNumber}
                        </Text>

                        <br />

                        <Button
                          onClick={() =>
                            navigateToOrders(
                              SupplierDetails[0].id,
                              SupplierDetails[0].name
                            )
                          }
                          width={"100"}
                          flex={1}
                        >
                          {t("Orders")}
                        </Button>
                      </>
                    )
                  )}
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button
                      variant="ghost"
                      colorScheme="blueGray"
                      onClick={() => {
                        setShowDetailsModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isLoading}
                      onClick={() => {
                        setShowDetailsModal(false);
                        setIsEditMode(true);
                        setIsAddMode(false);
                        setShowDetailsModal(false);
                      }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Stack>
        </Stack>
      </Stack>

      {/* ADD SUPPLIER MODAL */}
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isAddMode}
        onClose={() => {
          setIsAddMode(false);
          getAllSuppliers();
        }}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent maxWidth="350">
          <ModalCloseButton />
          <ModalHeader>Add Supplier</ModalHeader>
          <ModalBody>
            {modalIsLoading ? (
              <Loading />
            ) : (
              <Stack flex={1}>
                <Stack alignItems={"center"}>
                  <Stack>
                    <AddSupplier
                      name={""}
                      id={""}
                      location={""}
                      phoneNumber={""}
                      tin={""}
                      isEdit={isEditMode}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* EDIT CUSTOMER MODAL */}
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isEditMode}
        onClose={() => {
          setIsEditMode(false);
          getAllSuppliers();
        }}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent maxWidth="350">
          <ModalCloseButton />
          <ModalHeader>Customer Details</ModalHeader>
          <ModalBody>
            {modalIsLoading ? (
              <Loading />
            ) : (
              isEditMode && (
                <Stack flex={1}>
                  <Stack alignItems={"center"}>
                    <Stack>
                      <AddSupplier
                        name={SupplierDetails ? SupplierDetails[0].name : ""}
                        id={
                          SupplierDetails
                            ? SupplierDetails[0].id.toString()
                            : ""
                        }
                        location={
                          SupplierDetails ? SupplierDetails[0].location : ""
                        }
                        phoneNumber={
                          SupplierDetails ? SupplierDetails[0].phoneNumber : ""
                        }
                        tin={SupplierDetails ? SupplierDetails[0].tin : ""}
                        isEdit={isEditMode}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default SupplierScreen;
