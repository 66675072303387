import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { SessionList } from "../utils/types";
import {
  Button,
  Center,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";

const FryerScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [filteredSessions, setFilteredSessions] = useState<SessionList[]>([]);
  const [searchTerm, setSearchTerm] = useState<String>("");
  const [startTime, setStartTime] = useState<string>(moment().format("HH:mm")); // Default to current time
  const [startTemp, setStartTemp] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [sessionDate, setSessionDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  ); // Default to today’s date

  const { t, changeLanguage } = useCustomTranslation();
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
    getSessions();
    // getTotalBalance();
  }, []);

  const getSessions = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<SessionList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/getAll`,
        { headers }
      );

      console.log(response);
      // Sort filtered customers by balance in descending order
      const sortedFilteredData = response.data.sort(
        (a, b) => Number(b.session) - Number(a.session)
      );
      setFilteredSessions(sortedFilteredData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setModalIsLoading(true);

    const headers = await getHeadersForRequest();
    await axios
      .post(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/startSession`,
        {
          session: filteredSessions.length + 1,
          startTime: startTime,
          startTemp: startTemp,
          description: description,
          sessionDate: sessionDate,
        },
        { headers }
      )
      .then((response) => {
        toast({
          title: t("Saved successfully"),
          duration: 3000,
          status: "success",
        });
        setModalIsLoading(false);
        console.log(response);
        if (response.data.id > 0) {
          navigateToDetails(response.data.id);
        }
        setIsAddMode(false);
        getSessions();
      })
      .catch((error) => {
        console.error(error);
        setModalIsLoading(false);
        toast({
          title: t("Error saving"),
          duration: 3000,
          status: "error",
        });
      });
  };

  const navigateToDetails = (sessionId: number) => {
    navigate(`/FryerSessionDetails/${sessionId}`);
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav pageName={"Fryer Sessions"} />
        </Stack>
        <Stack px={1} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <InputGroup maxW={500}>
                  <Input
                    placeholder={t("Search for a session...")}
                    width="100%"
                    borderRadius="4"
                    py="3"
                    px="1"
                    fontSize="14"
                    onChange={(text) => setSearchTerm(text.target.value)}
                    autoCapitalize="none"
                  />
                  <InputRightAddon>{t("Search")}</InputRightAddon>
                </InputGroup>

                <Button
                  onClick={() => {
                    setIsAddMode(true);
                  }}
                >
                  {t("Add Session")}
                </Button>
              </HStack>

              <Stack h={10} />

              {filteredSessions.length > 0 ? (
                <TableContainer>
                  <Table variant="striped">
                    <TableCaption>{t("List of Sessions")}</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>{t("Session")}</Th>
                        <Th>{t("Session Date")}</Th>
                        <Th>{t("Start Time")}</Th>
                        <Th>{t("Start Temp")}</Th>
                        <Th>{t("End Time")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredSessions.map(
                        (session: SessionList, index: number) => (
                          <Tr
                            onClick={() => {
                              navigateToDetails(session.id);
                            }}
                          >
                            <Td>{session.session} </Td>
                            <Td>
                              {moment(session.sessionDate).format(
                                "DD MMM YYYY"
                              )}
                            </Td>

                            <Td>
                              {moment
                                .utc(session.startTime, "HH:mm:ss")
                                .format("hh:mm a")}
                            </Td>
                            <Td>{session.startTemp}°C</Td>
                            <Td>
                              {session.endTime === null
                                ? "n/a"
                                : moment
                                    .utc(session.endTime, "HH:mm:ss")
                                    .format("hh:mm a")}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    {t("There are no Sessions")}
                  </Text>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    Click the + button to add one!
                  </Text>
                </Stack>
              )}
            </Stack>
          )}

          {/* ADD SESSION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isAddMode}
            onClose={() => {
              setIsAddMode(false);
              getSessions();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Add Session")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  <Stack flex={1}>
                    <Stack alignItems={"center"}>
                      <Stack>
                        <Text>Start Time*</Text>
                        <Input
                          type="time"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                        />

                        <Text>Start Temperature*</Text>
                        <Input
                          type="number"
                          value={startTemp}
                          onChange={(e) =>
                            setStartTemp(parseFloat(e.target.value))
                          }
                        />

                        <Text>Description</Text>
                        <Textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />

                        <Text>Session Date*</Text>
                        <Input
                          type="date"
                          value={sessionDate}
                          onChange={(e) => setSessionDate(e.target.value)}
                        />

                        <br />

                        <Button
                          onClick={handleSave}
                          // onClick={()=>console.log(sessions.length)}
                          isLoading={isLoading}
                          colorScheme="blue"
                        >
                          Submit
                        </Button>

                        <br />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FryerScreen;
