import Cookies from "js-cookie";
import { taxRates } from "./strings";
import { OrderList } from "./types";

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const checkCookies = async () => {
  try {
    const emailAddress = Cookies.get("emailAddress");
    const deviceId = Cookies.get("deviceId");
    const userToken = Cookies.get("userToken");

    if (userToken === null || emailAddress === null || deviceId === null) {
      return 404;
    } else {
      return {
        userToken: userToken,
        emailAddress: emailAddress,
        deviceId: deviceId,
      };
    }
  } catch (error) {
    return 404;
  }
};

export const getHeadersForRequest = async () => {
  try {
    const emailAddress = Cookies.get("emailAddress");
    const deviceId = Cookies.get("deviceId");
    const userToken = Cookies.get("userToken");
    return {
      userToken: userToken,
      emailAddress: emailAddress,
      deviceId: deviceId,
    };
  } catch (error) {
    console.error(error);
  }
};

export function getDescriptionById(id: number) {
  const item = taxRates.find(item => item.id === id);
  return item ? item.description : undefined;
}

export function isFirstOrderType(order: OrderList): order is {
  id: number;
  flavour: string;
  packetNumber: string;
  size: string;
  price: string;
  product: string;
  transactionNumber: number;
  userId: string;
  transactionDate: Date;
  totalAmount: number;
  customerId: number;
  paymentStatus: number;
  paymentDate: Date;
  deliveryStatus: boolean;
  deliveryDate: Date;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  transactionType: number;
  supplierId: number;
  invoiceDate: Date;
  isVoid: boolean;
  name: string;
  tin: string;
  phoneNumber: string;
  location: string;
  traCode: string;
  traTime: string;
  QRLink: string;
  traReceiptNumber: string; 
} {
  return 'flavour' in order;
}
