import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { TransactionCard } from "../components/TransactionCard";
import {
  FaEdit,
  FaEye,
  FaPenNib,
  FaPencilAlt,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import Cookies from "js-cookie";
import { t } from "i18next";
import useCustomTranslation from "../hooks/useCustomTranslation";
import * as XLSX from "xlsx";
import moment from "moment";
import { SessionData } from "../utils/types";
import { LockIcon, RepeatIcon } from "@chakra-ui/icons";

interface Transaction {
  id: number;
  transactionNumber: string;
  invoiceDate: Date;
  paymentStatus: boolean;
  deliveryStatus: boolean;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  traReceiptNumber: string;
  traTime: string;
  accountName: string;
}

type TransactionsList = {
  name: string;
  id: number;
  transactionNumber: string;
  invoiceDate: Date;
  totalAmount: number;
  paymentStatus: boolean;
  deliveryStatus: boolean;
  transactionType: number;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  isVoid: boolean;
  traReceiptNumber: string;
  traTime: string;
  accountName: string;
};

const ProductionSessionDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEndSessionModal, setShowEndSessionModal] =
    useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TransactionsList[]>([]);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");

  const [sessionData, setSessionData] = useState<SessionData[]>([]);

  const [loadInTime, setLoadInTime] = useState<string>("");
  const [loadInTemp, setLoadInTemp] = useState<number | "">("");
  const [loadOutTime, setLoadOutTime] = useState<string>("");
  const [loadOutTemp, setLoadOutTemp] = useState<number | "">("");
  const [lowestPointTemp, setLowestPointTemp] = useState<number | "">("");
  const [notes, setNotes] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const today = new Date();
  today.setDate(today.getDate() - 90);

  const [startDate, setStartDate] = useState<string>(today.toISOString());
  const toast = useToast();
  const navigate = useNavigate();

  const { t, changeLanguage } = useCustomTranslation();

  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  useEffect(() => {
    setIsLoading(true);
    getSessionDetails();
  }, []);

  // const getSessionDetails = async () => {
  //   setIsLoading(true);
  //   const headers = await getHeadersForRequest();
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_TEST_API_URL}/fryer/getdetails`,
  //       { id: sessionId },
  //       { headers }
  //     );
  //     console.log(response.data);
  //     setIsLoading(false);
  //     setSessionData(response.data);
  //     // setCustomerBalance(balance);
  //   } catch (error) {
  //     console.error(error);
  //     setIsLoading(false);
  //   }
  // };

  const getSessionDetails = async () => {
    // const [sessionData, setSessionData] = useState<SessionData[]>([]);
    // const [isLoading, setIsLoading] = useState<boolean>(false);

    setIsLoading(true);
    const headers = await getHeadersForRequest();

    try {
      const response = await axios.post<SessionData[]>(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/getdetails`,
        { id: sessionId },
        { headers }
      );

      const data = response.data;

      // Calculate timeToStart and add it to each item in the array
      const enrichedData: SessionData[] = data.map((item, index, array) => {
        let timeToStart: number;

        if (index === 0) {
          // For the first item, calculate difference between loadInTime and startTime
          timeToStart = moment
            .utc(item.loadInTime, "HH:mm:ss")
            .diff(moment.utc(item.startTime, "HH:mm:ss"), "minutes");
        } else {
          // For subsequent items, calculate difference between loadInTime and previous item's loadInTime
          timeToStart = moment
            .utc(item.loadInTime, "HH:mm:ss")
            .diff(
              moment.utc(array[index - 1].loadOutTime, "HH:mm:ss"),
              "minutes"
            );
        }

        return {
          ...item,
          timeToStart, // Add the calculated field
        };
      });

      setIsLoading(false);
      setSessionData(enrichedData); // Update state with enriched data
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Helper function to format minutes to "X hrs and Y mins"
  const formatMinutesToHoursAndMinutes = (minutes: number): string => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hrs} hrs  ${mins} mins`;
  };

  const handleAdd = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/addSessionData`,
        {
          loadInTime: loadInTime,
          loadInTemp: loadInTemp,
          loadOutTime: loadOutTime,
          loadOutTemp: loadOutTemp,
          lowestPointTemp: lowestPointTemp,
          notes: notes,
          sessionId: sessionId,
        },
        { headers }
      );
      console.log(response.data);
      setModalIsLoading(false);
      // setSessionData(response.data);
      // setCustomerBalance(balance);
      getSessionDetails();
      setShowAddModal(false);
      resetForm();
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  const handleEnd = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/endSession`,
        {
          sessionId: sessionId,
          endTime: endTime,
        },
        { headers }
      );
      console.log(response.data);
      setModalIsLoading(false);
      // setSessionData(response.data);
      // setCustomerBalance(balance);
      getSessionDetails();
      setShowEndSessionModal(false);
      resetForm();
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  const resetForm = () => {
    setLoadInTime("");
    setLoadInTemp("");
    setLoadOutTime("");
    setLoadOutTemp("");
    setLowestPointTemp("");
    setNotes("");
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Production Session Details"} />
      </Stack>
      <Stack flex={1}>
        <Stack pt={2} pb={10} px={3}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <>
              <HStack justifyContent={"space-between"}>
                <Text>
                  {t("Batch count:")}{" "}
                  {sessionData.length === 1 && sessionData[0]?.id == null
                    ? "0"
                    : sessionData.length}
                </Text>
                {sessionData.length > 0 && sessionData[0].endTime === null ? (
                  <Button
                    colorScheme="blue"
                    onClick={() => setShowAddModal(true)}
                  >
                    Add Batch Details
                  </Button>
                ) : null}
                <HStack>
                  <IconButton
                    onClick={() => getSessionDetails()}
                    isRound={true}
                    variant="ghost"
                    // colorScheme="green"
                    aria-label="Done"
                    fontSize="20px"
                    icon={<RepeatIcon />}
                  />
                  {sessionData.length > 0 &&
                    sessionData[0].endTime === null && (
                      <IconButton
                        onClick={() => setShowEndSessionModal(true)}
                        isRound={true}
                        variant="ghost"
                        // colorScheme="green"
                        aria-label="Done"
                        fontSize="20px"
                        icon={<LockIcon />}
                      />
                    )}
                </HStack>
              </HStack>

              {sessionData.length > 0 && (
                <HStack
                  justifyContent={"space-between"}
                  flexDir={"row-reverse"}
                >
                  <Text>
                    {moment(sessionData[0].sessionDate).format("DD MMM YYYY")}
                  </Text>

                  <Stack>
                    <Text>
                      Start temperature: {sessionData[0].startTemp} °C
                    </Text>
                    <Text>
                      Start time:{" "}
                      {moment
                        .utc(sessionData[0].startTime, "HH:mm:ss")
                        .format("hh:mm a")}
                    </Text>
                    {sessionData[0].endTime !== null && (
                      <Text>
                        End time:{" "}
                        {moment
                          .utc(sessionData[0].endTime, "HH:mm:ss")
                          .format("hh:mm a")}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              )}

              {sessionData.length > 0 && sessionData[0]?.id !== null && (
                <Stack>
                  <TableContainer>
                    <Table variant="striped">
                      <TableCaption>{t("List of Batches")}</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Batch</Th>
                          <Th>{t("Load In")}</Th>
                          <Th>{t("Start Temp")}</Th>
                          <Th>{t("Load Out")}</Th>
                          <Th>{t("End Temp")}</Th>
                          <Th>{t("Lowest Temp")}</Th>
                          <Th>{t("Time to Start")}</Th>
                          <Th>{t("Temp Dip")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {sessionData.map(
                          (session: SessionData, index: number) => (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>
                                {moment
                                  .utc(session.loadInTime, "HH:mm:ss")
                                  .format("hh:mm a")}
                              </Td>
                              <Td>{session.loadInTemp} °C</Td>
                              <Td>
                                {moment
                                  .utc(session.loadOutTime, "HH:mm:ss")
                                  .format("hh:mm a")}
                              </Td>
                              <Td>{session.loadOutTemp} °C</Td>
                              <Td>{session.lowestPointTemp} °C</Td>
                              <Td>
                                {session.timeToStart
                                  ? session.timeToStart > 60
                                    ? formatMinutesToHoursAndMinutes(
                                        session.timeToStart
                                      )
                                    : session.timeToStart + " mins"
                                  : 0}
                              </Td>
                              <Td>
                                {(
                                  parseFloat(session.loadInTemp) -
                                  parseFloat(session.lowestPointTemp)
                                ).toFixed(1)}{" "}
                                °C
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                      {/* <Tfoot>
                      <Tr>
                        <Th>ID</Th>
                        <Th>{t("Session Date")}</Th>
                        <Th>{t("Session")}</Th>
                        <Th>{t("Start Time")}</Th>
                        <Th>{t("Start Temp")}</Th>
                        <Th>{t("Description")}</Th>
                      </Tr>
                    </Tfoot> */}
                    </Table>
                  </TableContainer>

                  {/* {sessionData[0].endTime === null && (
                    <Button
                      mt={2}
                      colorScheme="red"
                      onClick={() => setShowEndSessionModal(true)}
                    >
                      End Session
                    </Button>
                  )} */}
                </Stack>
              )}

              {sessionData.length === 1 && sessionData[0]?.id == null && (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    {t("There is no session data to show")}
                  </Text>
                  <Text fontSize={20} textAlign={"center"}>
                    {t("Click on the add button to start")}
                  </Text>

                  <Button onClick={() => setShowAddModal(true)}>Add</Button>
                </Stack>
              )}
            </>
          )}

          {/* ADD BATCH INFO MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showAddModal}
            onClose={() => setShowAddModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Record")}</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text>Load In Time</Text>
                  <Input
                    type="time"
                    value={loadInTime}
                    onChange={(e) => setLoadInTime(e.target.value)}
                  />

                  <Text>Load In Temperature</Text>
                  <Input
                    type="number"
                    value={loadInTemp}
                    onChange={(e) =>
                      setLoadInTemp(
                        e.target.value ? parseFloat(e.target.value) : ""
                      )
                    }
                  />

                  <Text>Load Out Time</Text>
                  <Input
                    type="time"
                    value={loadOutTime}
                    onChange={(e) => setLoadOutTime(e.target.value)}
                  />

                  <Text>Load Out Temperature</Text>
                  <Input
                    type="number"
                    value={loadOutTemp}
                    onChange={(e) =>
                      setLoadOutTemp(
                        e.target.value ? parseFloat(e.target.value) : ""
                      )
                    }
                  />

                  <Text>Lowest Point Temperature</Text>
                  <Input
                    type="number"
                    value={lowestPointTemp}
                    onChange={(e) =>
                      setLowestPointTemp(
                        e.target.value ? parseFloat(e.target.value) : ""
                      )
                    }
                  />

                  <Text>Notes</Text>
                  <Textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowAddModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    isLoading={modalIsLoading}
                    colorScheme="blue"
                    onClick={handleAdd}
                  >
                    {t("Add")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* END SESSION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showEndSessionModal}
            onClose={() => setShowEndSessionModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("End Session")}</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text>End Time</Text>
                  <Input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowEndSessionModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    isLoading={modalIsLoading}
                    colorScheme="blue"
                    onClick={handleEnd}
                  >
                    {t("End")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProductionSessionDetails;
