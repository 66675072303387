import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import logo from "../resources/images/logo.jpg";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setIsLoading(true);

    if (email === "" || password === "") {
      alert("Please fill in all the fields");
      setIsLoading(false);
      return;
    }

    const deviceDetails = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      cookieEnabled: navigator.cookieEnabled,
    };

    try {
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const ip = ipResponse.data.ip;

      const locationResponse = await axios.get(`https://ipapi.co/${ip}/json/`);

      const locationDetails = {
        ip: ip,
        city: locationResponse.data.city,
        region: locationResponse.data.region,
        country: locationResponse.data.country_name,
        latitude: locationResponse.data.latitude,
        longitude: locationResponse.data.longitude,
      };

      console.log("Device details " + JSON.stringify(deviceDetails));
      console.log("Location details " + JSON.stringify(locationDetails));

      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/auth/login`,
        {
          emailAddress: email,
          password: password,
          country: locationDetails.country,
          device: deviceDetails.platform,
        }
      );

      console.log(response);

      if (response.data.token) {
        Cookies.set("userToken", response.data.token, { path: "/" });
        Cookies.set("emailAddress", email, { path: "/" });
        Cookies.set("deviceId", response.data.deviceId.toString());
        Cookies.set("userName", response.data.userName);
        Cookies.set("businessName", response.data.businessName);
        if (response.data.profilePic){
          const base64WithPrefix = `data:image/jpeg;base64,${response.data.profilePic.split("base64").pop()}`;
          localStorage.setItem("profilePic", base64WithPrefix.toString());
        }
       
        navigate("/dashboard");
      } else {
        alert("Login failed");
      }
    } catch (error) {
      console.error("Login error", error);
      alert("Login error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack alignItems={"flex-end"} pr={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
      </Stack>

      <Flex align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Image
              src={logo}
              maxH={"200px"}
              bg={useColorModeValue("transparent", "#fff")}
              borderRadius={10}
              px={3}
              py={2}
            />
          </Stack>

          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack mb={5}>
              <Heading>Login</Heading>
            </Stack>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <EmailIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    size="xl"
                    type="email"
                    w={"100%"}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </InputGroup>
              </FormControl>

              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LockIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    size="xl"
                    type="password"
                    w={"100%"}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                  />
                </InputGroup>
              </FormControl>

              <Stack spacing={10}>
                <Button
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={handleLogin}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default Login;
