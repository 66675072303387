import {
  Button,
  Checkbox,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { getHeadersForRequest } from "../utils/functions";
import { taxRates } from "../utils/strings";
import useCustomTranslation from "../hooks/useCustomTranslation";

type AddCustomerCardProps = {
  id?: string;
  name?: string;
  isVoid?: boolean;
  description?: string;
  taxCategory?: string;
  isEdit?: boolean;
};

export const AddProduct: React.FC<AddCustomerCardProps> = ({
  id,
  name,
  isVoid,
  description,
  taxCategory,
  isEdit,
}) => {
  const [name1, setname] = useState<string>(name || "");
  const [isVoid1, setisVoid] = useState<boolean>(isVoid || false);
  const [description1, setdescription] = useState<string>(description || "");
  const [taxCategory1, settaxCategory] = useState<string>(taxCategory || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();
  const { t, changeLanguage } = useCustomTranslation();

  const handleEdit = async () => {
    setIsLoading(true);
    if (taxCategory1.trim() === "") {
      toast({
        title: "Please select a tax category!",
        duration: 3000,
        status: "error",
      });
      setIsLoading(false);
      return;
    }
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "warning",
      });
      setIsLoading(false);
    } else {
      const headers = await getHeadersForRequest();

      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/products/update`,
          {
            id: id,
            name: name1,
            isVoid: isVoid1,
            description: description1,
            taxCategory: taxCategory1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setname("");
          setisVoid(false);
          setdescription("");
          settaxCategory("");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (taxCategory1.trim() === "") {
      toast({
        title: "Please select a tax category!",
        duration: 3000,
        status: "error",
      });
      setIsLoading(false);
      return;
    }
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "error",
      });
      setIsLoading(false);
    } else {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/products/save`,
          {
            name: name1,
            isVoid: isVoid1,
            description: description1,
            taxCategory: taxCategory1,
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setname("");
          setisVoid(false);
          setdescription("");
          settaxCategory("");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };
  return (
    <Stack>
      <Text>{t("Name")}</Text>
      <Input
        defaultValue={name1}
        onChange={(e) => setname(e.target.value)}
        placeholder={t("Enter name")}
        autoCapitalize={"words"}
      />

      <br />
      <br />

      <Text>{t("Product Description")}</Text>
      <Input
        defaultValue={description1}
        onChange={(e) => setdescription(e.target.value)}
        placeholder={t("Enter description")}
      />

      <br />
      <br />
      <Text>{t("Tax Category")}</Text>
      <Select
        minW={"300"}
        flex={1}
        onChange={(e) => settaxCategory(e.target.value)}
      >
        <option value={""}>{t("Pick Tax Category")}</option>
        {taxRates.map((category) => (
          <option value={category.id.toString()}>{category.description}</option>
        ))}
      </Select>

      <br />
      <br />

      {isEdit && (
        <Checkbox
          defaultChecked={isVoid1}
          onChange={() => {
            setisVoid(!isVoid1);
          }}
        >
          {t("Disabled")}
        </Checkbox>
      )}

      <br />
      <br />

      <Button onClick={isEdit ? handleEdit : handleSave} isLoading={isLoading}>
        <Text>{isEdit ? t("Edit") : t("Save")}</Text>
      </Button>
    </Stack>
  );
};
