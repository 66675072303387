import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { t } from "i18next";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment, { Moment } from "moment";
import { SessionData } from "../utils/types";
import { LockIcon, RepeatIcon } from "@chakra-ui/icons";

const FryerSessionDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEndSessionModal, setShowEndSessionModal] =
    useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);

  const [sessionData, setSessionData] = useState<SessionData[]>([]);

  const [loadInTime, setLoadInTime] = useState<string>("");
  const [loadInTemp, setLoadInTemp] = useState<number | "">("");
  const [loadOutTime, setLoadOutTime] = useState<string>("");
  const [loadOutTemp, setLoadOutTemp] = useState<number | "">("");
  const [lowestPointTemp, setLowestPointTemp] = useState<number | "">("");
  const [notes, setNotes] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [nextStartTime, setNextStartTime] = useState<any>();

  const today = new Date();
  today.setDate(today.getDate() - 90);

  const { t, changeLanguage } = useCustomTranslation();

  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  useEffect(() => {
    setIsLoading(true);
    getSessionDetails();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(getSessionDetails, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const getSessionDetails = async () => {
    // setIsLoading(true);
    const headers = await getHeadersForRequest();

    try {
      const response = await axios.post<SessionData[]>(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/getdetails`,
        { id: sessionId },
        { headers }
      );

      const data = response.data;

      if (data.length > sessionData.length) {
        // Calculate timeToStart and add it to each item in the array
        const enrichedData: SessionData[] = data.map((item, index, array) => {
          let timeToStart: number;
          let speed: number;

          if (index === 0) {
            // For the first item, calculate difference between loadInTime and startTime
            timeToStart = moment
              .utc(item.loadInTime, "HH:mm:ss")
              .diff(moment.utc(item.startTime, "HH:mm:ss"), "minutes");

            speed =
              (parseFloat(item.loadInTemp) - parseFloat(item.startTemp)) /
              timeToStart;
          } else {
            // For subsequent items, calculate difference between loadInTime and previous item's loadInTime
            timeToStart = moment
              .utc(item.loadInTime, "HH:mm:ss")
              .diff(
                moment.utc(array[index - 1].loadOutTime, "HH:mm:ss"),
                "minutes"
              );

            speed =
              (parseFloat(item.loadInTemp) -
                parseFloat(array[index - 1].lowestPointTemp)) /
              timeToStart;
          }

          return {
            ...item,
            timeToStart,
            speed, // Add the calculated field
          };
        });

        setIsLoading(false);
        setSessionData(enrichedData); // Update state with enriched data
        if (response.data.length > 0) {
          getNextStartTime(enrichedData);
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Helper function to format minutes to "X hrs and Y mins"
  const formatMinutesToHoursAndMinutes = (minutes: number): string => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hrs} hrs  ${mins} mins`;
  };

  function getNextStartTime(dataArray: SessionData[]) {
    if (dataArray.length === 0) {
      console.log("Data array is empty.");
      return null;
    }

    // Retrieve last session entry
    const lastSession = dataArray[dataArray.length - 1];

    // console.log(JSON.stringify(lastSession));

    // Parse necessary fields from the last session
    const startTemp = parseFloat(lastSession.loadOutTemp);
    const targetTemp = parseFloat(lastSession.loadInTemp); // Use loadInTemp as target temperature
    const loadOutTime = lastSession.loadOutTime;
    const speed = lastSession.speed || 1; // Use calculated speed, default to 1 if not present

    // Calculate temperature difference and time required to reach target temperature
    const tempDifference = targetTemp - startTemp;
    const timeAdjustment = tempDifference / speed; // in milliseconds

    // console.log(
    //   "startTemp",
    //   startTemp,
    //   "targetTemp",
    //   targetTemp,
    //   "loadOutTime",
    //   loadOutTime,
    //   "speed",
    //   speed,
    //   "tempDifference",
    //   tempDifference,
    //   "timeAdjustment",
    //   timeAdjustment
    // );

    // Method 1
    // const time = moment(loadOutTime, "HH:mm:ssZ");
    // console.log(moment(time).format("HH:MM a"));

    // // Add the fractional minutes
    // const newTime = time.add(timeAdjustment, 'minutes');

    // console.log(moment(newTime).format("HH:MM a"))

    // Method 2
    // Combine sessionDate and loadOutTime to create the load out moment
    // const loadOutMoment = moment.utc(
    //   `${lastSession.sessionDate.split("T")[0]}T${loadOutTime}`
    // );

    // // Calculate the next expected start time
    // const nextStartTime = loadOutMoment
    //   .clone()
    //   .add(timeAdjustment, "milliseconds");

    // console.log(moment(nextStartTime).format("hh:MM a"));

    // Method 3
    // Extract hours, minutes, and seconds from the loadOutTime
    const [hours, minutes, seconds] = loadOutTime.split(/[:+]/).map(Number); // splits by ":" and "+"

    // Create a Date object with a fixed date and extracted time in UTC
    const date = new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds));

    // console.log("Parsed Date:", date);

    // Check if the date parsing was successful
    if (isNaN(date.getTime())) {
      // console.error("Invalid date format:", loadOutTime);
    } else {
      // Add minutes to the parsed date
      date.setMinutes(date.getMinutes() + timeAdjustment);

      // Format the result to HH:mm:ss
      const newTimeString = date.toISOString().substr(11, 8);

      // date.setUTCMinutes(date.getUTCMinutes() + timeAdjustment);

      // Format the result to "hh:mm a"
      // const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
      // const newTimeString = date.toLocaleTimeString('en-US', options);

      // console.log("Updated Time:", newTimeString);
      const formattedTime = newTimeString.split(":");
      setNextStartTime(formattedTime[0] + ":" + formattedTime[1]);
    }
    // Format the date back to HH:mm:ss
    // const newTimeString = date.toISOString().substr(11, 8); // Extracting only the time part

    // setNextStartTime(newTimeString);
  }

  const handleAdd = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/addSessionData`,
        {
          loadInTime: loadInTime,
          loadInTemp: loadInTemp,
          loadOutTime: loadOutTime,
          loadOutTemp: loadOutTemp,
          lowestPointTemp: lowestPointTemp,
          notes: notes,
          sessionId: sessionId,
        },
        { headers }
      );
      console.log(response.data);
      setModalIsLoading(false);
      // setSessionData(response.data);
      // setCustomerBalance(balance);
      reloadSessionList();
      setShowAddModal(false);
      resetForm();
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  const handleEnd = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/endSession`,
        {
          sessionId: sessionId,
          endTime: endTime,
        },
        { headers }
      );
      console.log(response.data);
      setModalIsLoading(false);
      getSessionDetails();
      setShowEndSessionModal(false);
      resetForm();
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  const reloadSessionList = async () => {
    setIsReloading(true);
    const headers = await getHeadersForRequest();

    try {
      const response = await axios.post<SessionData[]>(
        `${process.env.REACT_APP_TEST_API_URL}/fryer/getdetails`,
        { id: sessionId },
        { headers }
      );

      const data = response.data;

      // Calculate timeToStart and add it to each item in the array
      const enrichedData: SessionData[] = data.map((item, index, array) => {
        let timeToStart: number;
        let speed: number;

        if (index === 0) {
          // For the first item, calculate difference between loadInTime and startTime
          timeToStart = moment
            .utc(item.loadInTime, "HH:mm:ss")
            .diff(moment.utc(item.startTime, "HH:mm:ss"), "minutes");

          speed =
            (parseFloat(item.loadInTemp) - parseFloat(item.startTemp)) /
            timeToStart;
        } else {
          // For subsequent items, calculate difference between loadInTime and previous item's loadInTime
          timeToStart = moment
            .utc(item.loadInTime, "HH:mm:ss")
            .diff(
              moment.utc(array[index - 1].loadOutTime, "HH:mm:ss"),
              "minutes"
            );

          speed =
            (parseFloat(item.loadInTemp) -
              parseFloat(array[index - 1].lowestPointTemp)) /
            timeToStart;
        }

        return {
          ...item,
          timeToStart,
          speed, // Add the calculated field
        };
      });

      setIsReloading(false);
      setSessionData(enrichedData); // Update state with enriched data
      if (response.data.length > 0) {
        getNextStartTime(enrichedData);
      }
    } catch (error) {
      console.error(error);
      setIsReloading(false);
    }
  };

  const resetForm = () => {
    setLoadInTime("");
    setLoadInTemp("");
    setLoadOutTime("");
    setLoadOutTemp("");
    setLowestPointTemp("");
    setNotes("");
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Session Details"} />
      </Stack>
      <Stack flex={1}>
        <Stack pt={2} pb={10} px={3}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <>
              <HStack justifyContent={"space-between"}>
                <Text>
                  {t("Batch count:")}{" "}
                  {sessionData.length === 1 && sessionData[0]?.id == null
                    ? "0"
                    : sessionData.length}
                </Text>
                {sessionData.length > 0 && sessionData[0].endTime === null ? (
                  <Button
                    colorScheme="blue"
                    onClick={() => setShowAddModal(true)}
                  >
                    Add Batch Details
                  </Button>
                ) : null}
                <HStack>
                  <IconButton
                    onClick={reloadSessionList}
                    isRound={true}
                    variant="ghost"
                    // colorScheme="green"
                    aria-label="Done"
                    fontSize="20px"
                    icon={<RepeatIcon />}
                  />
                  {sessionData.length > 0 &&
                    sessionData[0].endTime === null && (
                      <IconButton
                        onClick={() => setShowEndSessionModal(true)}
                        isRound={true}
                        variant="ghost"
                        // colorScheme="green"
                        aria-label="Done"
                        fontSize="20px"
                        icon={<LockIcon />}
                      />
                    )}
                </HStack>
              </HStack>

              {sessionData.length > 0 && (
                <HStack
                  justifyContent={"space-between"}
                  flexDir={"row-reverse"}
                >
                  <Text>
                    {moment(sessionData[0].sessionDate).format("DD MMM YYYY")}
                  </Text>

                  <Stack>
                    <Text>
                      Start temperature: {sessionData[0].startTemp} °C
                    </Text>
                    <Text>
                      Start time:{" "}
                      {moment
                        .utc(sessionData[0].startTime, "HH:mm:ss")
                        .format("hh:mm a")}
                    </Text>
                    {sessionData[0].endTime !== null && (
                      <Text>
                        End time:{" "}
                        {moment
                          .utc(sessionData[0].endTime, "HH:mm:ss")
                          .format("hh:mm a")}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              )}

              {sessionData.length > 0 && sessionData[0]?.id !== null && (
                <Stack>
                  <TableContainer>
                    <Table variant="striped">
                      <TableCaption>{t("List of Batches")}</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Batch</Th>
                          <Th>{t("Load In")}</Th>
                          <Th>{t("Start Temp")}</Th>
                          <Th>{t("Load Out")}</Th>
                          <Th>{t("End Temp")}</Th>
                          <Th>{t("Lowest Temp")}</Th>
                          <Th>{t("Time to Start")}</Th>
                          <Th>{t("Time to Fry")}</Th>
                          <Th>{t("Temp Dip")}</Th>
                          <Th>{t("Speed")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {!isReloading &&
                          sessionData.map(
                            (session: SessionData, index: number) => (
                              <Tr>
                                <Td>{index + 1}</Td>
                                <Td>
                                  {moment
                                    .utc(session.loadInTime, "HH:mm:ss")
                                    .format("hh:mm a")}
                                </Td>
                                <Td>{session.loadInTemp} °C</Td>
                                <Td>
                                  {moment
                                    .utc(session.loadOutTime, "HH:mm:ss")
                                    .format("hh:mm a")}
                                </Td>
                                <Td>{session.loadOutTemp} °C</Td>
                                <Td>{session.lowestPointTemp} °C</Td>
                                <Td>
                                  {session.timeToStart
                                    ? session.timeToStart > 60
                                      ? formatMinutesToHoursAndMinutes(
                                          session.timeToStart
                                        )
                                      : session.timeToStart + " mins"
                                    : 0}
                                </Td>
                                <Td>
                                  {moment(
                                    session.loadOutTime,
                                    "HH:mm:ssZ"
                                  ).diff(
                                    moment(session.loadInTime, "HH:mm:ssZ"),
                                    "minutes"
                                  )}{" "}
                                  mins
                                </Td>
                                <Td>
                                  {(
                                    parseFloat(session.loadInTemp) -
                                    parseFloat(session.lowestPointTemp)
                                  ).toFixed(1)}{" "}
                                  °C
                                </Td>
                                <Td>
                                  {session.speed
                                    ? session.speed.toFixed(2)
                                    : session.speed}{" "}
                                  °C/min
                                </Td>
                              </Tr>
                            )
                          )}
                        {isReloading && (
                          <>
                            {sessionData.map(
                              (session: SessionData, index: number) => (
                                <Tr>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                  <Td>
                                    <Skeleton h={5}></Skeleton>
                                  </Td>
                                </Tr>
                              )
                            )}
                          </>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  {sessionData.length > 0 &&
                    sessionData[0].endTime === null && (
                      <Text textAlign={"center"}>
                        Expected start time for next session: {nextStartTime}
                      </Text>
                    )}
                </Stack>
              )}

              {sessionData.length === 1 && sessionData[0]?.id == null && (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    {t("There is no session data to show")}
                  </Text>
                  <Text fontSize={20} textAlign={"center"}>
                    {t("Click on the add button to start")}
                  </Text>

                  <Button onClick={() => setShowAddModal(true)}>Add</Button>
                </Stack>
              )}
            </>
          )}

          {/* ADD BATCH INFO MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showAddModal}
            onClose={() => setShowAddModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Record")}</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text>Load In Time</Text>
                  <Input
                    type="time"
                    value={loadInTime}
                    onChange={(e) => setLoadInTime(e.target.value)}
                  />

                  <Text>Load In Temperature</Text>
                  <Input
                    type="number"
                    value={loadInTemp}
                    onChange={(e) =>
                      setLoadInTemp(
                        e.target.value ? parseFloat(e.target.value) : ""
                      )
                    }
                  />

                  <Text>Load Out Time</Text>
                  <Input
                    type="time"
                    value={loadOutTime}
                    onChange={(e) => setLoadOutTime(e.target.value)}
                  />

                  <Text>Load Out Temperature</Text>
                  <Input
                    type="number"
                    value={loadOutTemp}
                    onChange={(e) =>
                      setLoadOutTemp(
                        e.target.value ? parseFloat(e.target.value) : ""
                      )
                    }
                  />

                  <Text>Lowest Point Temperature</Text>
                  <Input
                    type="number"
                    value={lowestPointTemp}
                    onChange={(e) =>
                      setLowestPointTemp(
                        e.target.value ? parseFloat(e.target.value) : ""
                      )
                    }
                  />

                  <Text>Notes</Text>
                  <Textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowAddModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    isLoading={modalIsLoading}
                    colorScheme="blue"
                    onClick={handleAdd}
                  >
                    {t("Add")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* END SESSION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showEndSessionModal}
            onClose={() => setShowEndSessionModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("End Session")}</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text>End Time</Text>
                  <Input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowEndSessionModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    isLoading={modalIsLoading}
                    colorScheme="blue"
                    onClick={handleEnd}
                  >
                    {t("End")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FryerSessionDetails;
