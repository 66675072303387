import {
  Button,
  Center,
  HStack,
  Heading,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import Nav from "../components/navbar";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";
import Line from "../components/analytics/LineTemplate";
import BarTemplate from "../components/analytics/BarTemplateProductRevenue";
import BarTemplateProductRevenue from "../components/analytics/BarTemplateProductRevenue";
import BarTemplateProductQty from "../components/analytics/BarTemplateProductQty";

// Define types for the API response
interface MonthlySales {
  [key: string]: number;
}

interface ProductSales {
  [key: string]: { quantity: number; revenue: number; size: number };
}

interface MostBoughtProduct {
  quantity: number;
  revenue: number;
}

interface ClientInsights {
  totalEarnings: number;
  monthlySales: MonthlySales;
  productSales: ProductSales;
  mostBoughtProduct: [string, MostBoughtProduct];
  paymentTrends: any[]; // Define this more precisely if you know the structure
  monthlyPayments: MonthlySales;
}

interface ApiResponse {
  message: string;
  clientInsights: ClientInsights;
}

const CustomerAnalyticsScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<number>(0);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [customerAnalytics, setCustomerAnalytics] = useState<ClientInsights | null>(null);
  const { t, changeLanguage } = useCustomTranslation();
  const toast = useToast();
  const { customerId, customerName } = useParams<{
    customerId: string;
    customerName: string;
  }>();

  useEffect(() => {
    loadCustomerAnalytics(customerId);
  }, [customerId]);

  const loadCustomerAnalytics = async (id: any) => {
    setIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<ApiResponse>(
        `${process.env.REACT_APP_TEST_API_URL}/analytics/analyzeCustomerTrend/${id}`,
        { headers }
      );
      setIsLoading(false);
      console.log("API response:", response.data);
      setCustomerAnalytics(response.data.clientInsights);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setCustomerAnalytics(null); // Set to null in case of error
    }
  };

  const handleTransactionClick = (transaction: number) => {
    console.log(transaction);
    setShowConfirmationModal(true);
    setSelectedTransaction(transaction);
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Analytics"}/>
      </Stack>
      <Stack flex={1}>
        <Stack pt={2} pb={10} px={3}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            customerAnalytics && (
              <>
                <Stack>
                  <HStack justifyContent={"space-between"}>
                    <Heading>{customerName}</Heading>
                    <Heading size="md" my={2}>
                      Total Earnings to-date:{" "}
                      {numberWithCommas(customerAnalytics.totalEarnings)}
                    </Heading>
                  </HStack>
                </Stack>

                {/* <Heading size="md" my={2}>Monthly Sales:</Heading>
                <Stack>
                  {Object.entries(customerAnalytics.monthlySales).map(([month, sales], index) => (
                    <Text key={index}>{moment(month).format("MMM YY")}: {numberWithCommas(sales as number)}</Text>
                  ))}
                </Stack> */}

                {/* <Heading size="md" my={2}>Product Sales:</Heading>
                <Stack>
                  {Object.entries(customerAnalytics.productSales).map(([product, details], index) => (
                    <Text key={index}>{product}: Quantity - {numberWithCommas(details.quantity)}, Revenue - {numberWithCommas(details.revenue)}</Text>
                  ))}
                </Stack> */}

                {/* <Heading size="md" my={2}>Most Bought Product:</Heading>
                <Stack>
                  <Text>{customerAnalytics.mostBoughtProduct[0]}</Text>
                  <Text>Quantity: {numberWithCommas(customerAnalytics.mostBoughtProduct[1].quantity)}</Text>
                  <Text>Revenue: {numberWithCommas(customerAnalytics.mostBoughtProduct[1].revenue)}</Text>
                </Stack> */}

                {customerAnalytics.totalEarnings > 0 && (
                  <>
                    <Heading size="md" my={2}>
                      Monthly Sales:
                    </Heading>
                    <Stack>
                      <Line saleData={customerAnalytics.monthlySales} paymentData={customerAnalytics.monthlyPayments}/>
                    </Stack>

                    <br />
                    <Heading size="md" my={2}>
                      Product Sales:
                    </Heading>
                    <Stack>
                      <BarTemplateProductRevenue
                        initialData={customerAnalytics.productSales}
                      />
                    </Stack>

                    <br />
                    <Heading size="md" my={2}>
                      Qty Sold:
                    </Heading>
                    <Stack>
                      <BarTemplateProductQty
                        initialData={customerAnalytics.productSales}
                      />
                    </Stack>
                  </>
                )}
              </>
            )
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerAnalyticsScreen;
