import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { ProductionList } from "../utils/types";
import {
  Button,
  Center,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";

const ProductionScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<ProductionList>();
  const [filteredSessions, setFilteredSessions] = useState<ProductionList[]>([]);
  const [searchTerm, setSearchTerm] = useState<String>("");
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD")); // Default to current time
  const [item, setItem] = useState<string>("");
  const [rawMaterialWeight, setRawMaterialWeight] = useState<number>(0);
  const [finishedMaterialWeight, setFinishedMaterialWeight] = useState<number>(0); // Default to today’s date
  const [burntWeight, setBurntWeight] = useState<number>(0);

  const { t, changeLanguage } = useCustomTranslation();
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
    getSessions();
  }, []);

  const getSessions = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<ProductionList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/production/getAll`,
        { headers }
      );

      console.log(response);
      // Sort filtered customers by balance in descending order
      const sortedFilteredData = response.data.sort(
        (a, b) => Number(b.id) - Number(a.id)
      );
      setFilteredSessions(sortedFilteredData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setModalIsLoading(true);

    const headers = await getHeadersForRequest();
    await axios
      .post(
        `${process.env.REACT_APP_TEST_API_URL}/production/saveSession`,
        {
          date: date,
          item: item,
          rawMaterialWeight: rawMaterialWeight,
          finishedMaterialWeight: finishedMaterialWeight,
          burntWeight: burntWeight,
        },
        { headers }
      )
      .then((response) => {
        toast({
          title: t("Saved successfully"),
          duration: 3000,
          status: "success",
        });
        setModalIsLoading(false);
        console.log(response);
        setIsAddMode(false);
        getSessions();
        resetForm();
      })
      .catch((error) => {
        console.error(error);
        setModalIsLoading(false);
        toast({
          title: t("Error saving"),
          duration: 3000,
          status: "error",
        });
      });
  };

  const handleUpdate = async () => {
    setModalIsLoading(true);

    const headers = await getHeadersForRequest();
    await axios
      .post(
        `${process.env.REACT_APP_TEST_API_URL}/production/updateDetails`,
        {
          sessionId: selectedSession?.id,
          finishedMaterialWeight: finishedMaterialWeight,
          burntWeight: burntWeight,
        },
        { headers }
      )
      .then((response) => {
        toast({
          title: t("Saved successfully"),
          duration: 3000,
          status: "success",
        });
        setModalIsLoading(false);
        console.log(response);
        setShowDetailsModal(false);
        getSessions();
        resetForm();
      })
      .catch((error) => {
        console.error(error);
        setModalIsLoading(false);
        toast({
          title: t("Error saving"),
          duration: 3000,
          status: "error",
        });
      });
  };

  const resetForm = () => {
    setDate(moment().format("YYYY-MM-DD"));
    setItem("");
    setRawMaterialWeight(0);
    setFinishedMaterialWeight(0);
    setBurntWeight(0);
  };

  return (
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav pageName={"Production Sessions"} />
        </Stack>
        <Stack px={1} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <InputGroup maxW={500}>
                  <Input
                    placeholder={t("Search for a session...")}
                    width="100%"
                    borderRadius="4"
                    py="3"
                    px="1"
                    fontSize="14"
                    onChange={(text) => setSearchTerm(text.target.value)}
                    autoCapitalize="none"
                  />
                  <InputRightAddon>{t("Search")}</InputRightAddon>
                </InputGroup>

                <Button
                  onClick={() => {
                    // setIsEditMode(false);
                    setIsAddMode(true);
                  }}
                >
                  {t("Add Session")}
                </Button>
              </HStack>

              <Stack h={10} />

              {filteredSessions.length > 0 ? (
                <TableContainer>
                  <Table variant="striped">
                    <TableCaption>{t("List of Sessions")}</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>{t("Session")}</Th>
                        <Th>{t("Session Date")}</Th>
                        <Th>{t("Item")}</Th>
                        <Th>{t("Raw M.")}</Th>
                        <Th>{t("Finished G.")}</Th>
                        <Th>{t("Burnt")}</Th>
                        <Th>{t("Estimated Value")}</Th>
                        <Th>{t("Output %")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredSessions.map(
                        (session: ProductionList, index: number) => (
                          <Tr
                            onClick={() => {
                              if (
                                Math.round(session.finishedMaterialWeight) === 0
                              ) {
                                resetForm();
                                setSelectedSession(session);
                                setShowDetailsModal(true);
                              }
                            }}
                          >
                            <Td>{session.id} </Td>
                            <Td>
                              {moment(session.date).format("DD MMM YYYY")}
                            </Td>
                            <Td>{session.item}</Td>
                            <Td>{session.rawMaterialWeight} KG</Td>
                            <Td>{session.finishedMaterialWeight} KG</Td>
                            <Td>{session.burntWeight} KG</Td>
                            <Td>
                              {numberWithCommas(
                                Math.round(
                                  session.finishedMaterialWeight * 18000
                                )
                              )}{" "}
                              Tsh
                            </Td>
                            <Td>
                              {((session.finishedMaterialWeight /
                                session.rawMaterialWeight) *
                                100).toFixed(2)}{" "}
                              %
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    {t("There are no Sessions")}
                  </Text>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    Click the + button to add one!
                  </Text>
                </Stack>
              )}
            </Stack>
          )}

          {/* ADD SESSION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isAddMode}
            onClose={() => {
              setIsAddMode(false);
              getSessions();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Add Session")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  <Stack flex={1}>
                    <Stack alignItems={"center"}>
                      <Stack>
                        <Text>Date*</Text>
                        <Input
                          type="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />

                        <Text>Item*</Text>
                        <Input
                          value={item}
                          onChange={(e) => setItem(e.target.value)}
                        />

                        <Text>Raw Material in KG*</Text>
                        <Input
                          type="number"
                          value={rawMaterialWeight}
                          onChange={(e) =>
                            setRawMaterialWeight(parseFloat(e.target.value))
                          }
                        />

                        <Text>Finished Product in KG (inclusive of burnt)</Text>
                        <Input
                          type="number"
                          value={finishedMaterialWeight}
                          onChange={(e) =>
                            setFinishedMaterialWeight(
                              parseFloat(e.target.value)
                            )
                          }
                        />

                        <Text>Burnt in KG</Text>
                        <Input
                          type="number"
                          value={burntWeight}
                          onChange={(e) =>
                            setBurntWeight(parseFloat(e.target.value))
                          }
                        />

                        <br />

                        <Button
                          onClick={handleSave}
                          // onClick={()=>console.log(sessions.length)}
                          isLoading={isLoading}
                          colorScheme="blue"
                        >
                          Submit
                        </Button>

                        <br />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* UPDATE SESSION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showDetailsModal}
            onClose={() => {
              setShowDetailsModal(false);
              getSessions();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Update Details")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  <Stack flex={1}>
                    <Stack alignItems={"center"}>
                      <Stack>
                        <Text>Date*</Text>
                        <Input
                          isDisabled
                          type="date"
                          value={moment(selectedSession?.date).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) => setDate(e.target.value)}
                        />

                        <Text>Item*</Text>
                        <Input
                          isDisabled
                          value={selectedSession?.item}
                          onChange={(e) => setItem(e.target.value)}
                        />

                        <Text>Raw Material in KG*</Text>
                        <Input
                          isDisabled
                          type="number"
                          value={selectedSession?.rawMaterialWeight}
                          onChange={(e) =>
                            setRawMaterialWeight(parseFloat(e.target.value))
                          }
                        />

                        <Text>
                          Finished Product in KG* (inclusive of burnt)
                        </Text>
                        <Input
                          type="number"
                          value={finishedMaterialWeight}
                          onChange={(e) =>
                            setFinishedMaterialWeight(
                              parseFloat(e.target.value)
                            )
                          }
                        />

                        <Text>Burnt in KG</Text>
                        <Input
                          type="number"
                          value={burntWeight}
                          onChange={(e) =>
                            setBurntWeight(parseFloat(e.target.value))
                          }
                        />

                        <br />

                        <Button
                          onClick={handleUpdate}
                          // onClick={()=>console.log(selectedSession)}
                          isLoading={isLoading}
                          colorScheme="blue"
                        >
                          Submit
                        </Button>

                        <br />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
  );
};

export default ProductionScreen;
