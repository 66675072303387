import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Stack, Text } from "@chakra-ui/react";
import { getHeadersForRequest } from "../utils/functions";

const PredictionTest = () => {
  const [paymentData, setPaymentData] = useState<[]>([]);
  const [transactionData, setTransactionData] = useState<[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>([]);
  const [prediction, setPrediction] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch data from Node.js API when the component loads
  useEffect(() => {
    const fetchPaymentHistory = async () => {
      const oldHeaders = await getHeadersForRequest();
      const newHeaders = {
        id: 4,
      };
      const headers = { ...oldHeaders, ...newHeaders };
      try {
        const response = await axios.get(
          "http://localhost:5001/payments/customer",
          {
            headers,
          }
        ); // Fetch for customerId 12
        const data = response.data;
        console.log("Payment JSON: " + JSON.stringify(response.data));
        setPaymentData(data); // Store the fetched data
      } catch (error) {
        console.error("Error fetching payment data:", error);
      }
    };

    const fetchOrderHistory = async () => {
      const oldHeaders = await getHeadersForRequest();
      const newHeaders = {
        id: 4,
      };
      const headers = { ...oldHeaders, ...newHeaders };
      try {
        const response = await axios.get(
          "http://localhost:5001/transactions/customer",
          {
            headers,
          }
        ); // Fetch for customerId 12
        const data = response.data;
        console.log("Transaction JSON: " + JSON.stringify(response.data));
        setTransactionData(data); // Store the fetched data
      } catch (error) {
        console.error("Error fetching payment data:", error);
      }
    };

    fetchPaymentHistory();
    fetchOrderHistory();
  }, []);

  const handlePredict = async () => {
    // const response = await axios.post<[]>(
    //   `${process.env.REACT_APP_TEST_API_URL}/accounts/getStatement`,
    //   { accountId: accountId },
    //   { headers }
    // );
    const body = {
      total: selectedCustomer.total_pyments || "",
      avg_amount: selectedCustomer.avg_payment_amount || "",
      days_between: selectedCustomer.days_between_payments || "",
    };

    console.log("JSON BODY: " + body);
    const response = await axios.post("http://localhost:5000/predict_payment", {
      body,
    });
    console.log(response);
    const data = response.data;
    setPrediction(data.next_payment_date);
  };

  const askGPT = async () => {
    setIsLoading(true);
    const newPaymentData = {
      paymentData: paymentData,
    };

    const newTransactionData = {
      transactionData: transactionData,
    };
    const body = { ...newPaymentData, ...newTransactionData };

    try {
      const response = await axios.post(
        "http://localhost:5001/analytics/askGPT",
        {
          body,
        }
      );
      const data = response.data;
      setIsLoading(false);
      setPrediction(data);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Stack>
      {paymentData.length > 0 &&
        paymentData.map((payment: any, key: number) => (
          <Button
            onClick={() => {
              setSelectedCustomer(payment);
              console.log("selected data: " + JSON.stringify(payment));
            }}
          >
            <Text>{payment.customerId}</Text>
          </Button>
        ))}

      <Button onClick={handlePredict}>Predict Payment</Button>

      <Button isLoading={isLoading} onClick={() => askGPT()}>
        ASK GPT
      </Button>

      {prediction !== null && <Text>{prediction}</Text>}
    </Stack>
  );
};

export default PredictionTest;
