import React, { useEffect, useRef } from "react";
import QRCode from "qrcode";

interface QRCodeComponentProps {
  text: string;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ text }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, text, (error) => {
        if (error) console.error(error);
        console.log("QR code generated!");
      });
    }
  }, [text]);

  return <canvas ref={canvasRef} />;
};

export default QRCodeComponent;
