import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Input,
  Select,
  Text,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
  Checkbox,
  useToast,
  ModalOverlay,
  IconButton,
  Center,
} from "@chakra-ui/react";

import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import Cookies from "js-cookie";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import {
  AccountList,
  CustomerList,
  PurchaseListItem,
  SupplierList,
} from "../utils/types";
import {
  getAccountsList,
  getCustomerList,
  getSupplierList,
} from "../utils/API_calls";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { Loading } from "../components/Loading";
import useCustomTranslation from "../hooks/useCustomTranslation";
// import Nav from "../components/navbar";

const Form1 = () => {
  const [listItems, setListItems] = useState<PurchaseListItem[]>([]);
  const [strPrice, setPrice] = useState<string>("");
  const [strQty, setQty] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<SupplierList[]>([]);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [prompt, setPrompt] = useState<boolean>(false);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [refInvoice, setRefInvoice] = useState<string>("");
  const [receiptTotal, setReceiptTotal] = useState<number>(0);
  const [sellerIsCustomer, setSellerIsCustomer] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("0");

  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    if (groupValues.includes("paid")) {
      setSelectedAccount("1");
    } else {
      setSelectedAccount("0");
    }
  }, [groupValues]);

  const toast = useToast();

  const handleAddItem = () => {
    const price = parseInt(strPrice);
    const qty = parseInt(strQty);

    if (
      price <= 0 ||
      qty <= 0 ||
      strPrice === "" ||
      strQty === "" ||
      description === ""
    ) {
      toast({
        title: "Please fill all fields",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else {
      let newItem: PurchaseListItem;

      // Check if the combination of product, packet size, and flavour has been added before
      const isDuplicate = listItems.some(
        (item) =>
          item.price === price &&
          item.qty === qty &&
          item.description === description
      );

      if (isDuplicate) {
        toast({
          title: "This combination already exists",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      newItem = { price, qty, description };

      setListItems([...listItems, newItem]);
      toast({
        title: "Added to the list!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setPrice("");
      setQty("");
      setDescription("");
    }
  };

  const handleClearList = () => {
    setListItems([]);
  };

  const retrieveCustomers = async () => {
    setModalIsLoading(true);
    const customers = await getCustomerList();
    if (customers.success === 1) {
      setCustomers(customers.data);
    } else {
      setCustomers([]);
    }
    setModalIsLoading(false);
  };

  const retrieveSuppliers = async () => {
    setModalIsLoading(true);
    const suppliers = await getSupplierList();
    if (suppliers.success === 1) {
      setSuppliers(suppliers.data);
    } else {
      setSuppliers([]);
    }
    setModalIsLoading(false);
  };

  const retrieveAccounts = async () => {
    setModalIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setModalIsLoading(false);
  };

  const getTotal = () => {
    const receiptTotal = listItems.reduce((acc, item) => {
      const price = Number(item.price);
      const qty = item.qty;
      const itemTotal = price * qty;
      return acc + itemTotal;
    }, 0);
    setReceiptTotal(receiptTotal);
  };

  const handleDeleteItem = (index: number) => {
    const newItems = [...listItems];
    newItems.splice(index, 1);
    setListItems(newItems);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceDate(e.target.valueAsDate || new Date());
  };

  const handleSaveData = async () => {
    const email = await Cookies.get("emailAddress");
    const data = {
      supplierId: sellerIsCustomer
        ? null
        : selectedSupplier === ""
        ? null
        : selectedSupplier,
      customerId: sellerIsCustomer ? selectedCustomer : null,
      paymentStatus: groupValues.includes("paid"),
      paymentDate: groupValues.includes("paid") ? new Date() : null,
      userId: email,
      deliveryStatus: groupValues.includes("delivered"),
      deliveryDate: groupValues.includes("delivered") ? new Date() : null,
      transactionDate: new Date(),
      physicalDeliveryNote: groupValues.includes("physical"),
      totalAmount: receiptTotal,
      items: listItems,
      transactionType: 2,
      invoiceDate: invoiceDate,
      deliveryNoteNumber: groupValues.includes("physical")
        ? deliveryNoteNumber
        : "",
      account: selectedAccount,
      refInvoice: groupValues.includes("physical") ? refInvoice : "",
    };

    setIsLoading(true);
    // console.log("Data " + JSON.stringify(data));
    // console.log(KOAS_API_URL);

    if (groupValues.includes("paid") && selectedAccount === "0") {
      toast({
        title: "Please select account",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // CHECK WHY THE URL IS NOT GETTING IMPORTED FROM .env
    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/save/expense`,
        data,
        { headers }
      );
      console.log(response.data);
      setIsLoading(false);
      setShowModal(false);
      handleClearList();
      toast({
        title: "Transaction Details Saved!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handlePromptClose = () => {
    setPrompt(false);
    retrieveCustomers();
    retrieveSuppliers();
    retrieveAccounts();
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        Expenses
      </Heading>
      <Stack px={5} alignItems={"center"} pb={10}>
        <Text color="grey" fontSize="lg" fontFamily="">
          Price
        </Text>

        <Stack width={300}>
          <Input
            fontSize="md"
            pl={5}
            borderRadius={30}
            defaultValue={strPrice}
            inputMode="numeric"
            onChange={(e) => setPrice(e.target.value)}
            value={strPrice}
            borderWidth={3}
          />
        </Stack>

        <Text color="grey" fontSize="lg" fontFamily="">
          Description
        </Text>

        <Stack width={300}>
          <Input
            fontSize="md"
            pl={5}
            borderRadius={30}
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            borderWidth={3}
          />
        </Stack>

        <Text color="grey" fontSize="lg" fontFamily="">
          Quantity
        </Text>

        <Stack width={300}>
          <Input
            fontSize="md"
            pl={5}
            borderRadius={30}
            defaultValue={strQty}
            onChange={(e) => setQty(e.target.value)}
            value={strQty}
            inputMode="numeric"
            borderWidth={3}
          />
        </Stack>
      </Stack>

      <Stack alignItems={"center"}>
        <Button
          variant={"solid"}
          onClick={handleAddItem}
          borderRadius={20}
          width={200}
          colorScheme="blue"
        >
          <Text>CALCULATE</Text>
        </Button>
      </Stack>

      {listItems.length > 0 && (
        <Stack>
          <TableContainer>
            <Table variant="simple">
              <TableCaption> Number of Items: {listItems.length}</TableCaption>
              <Thead>
                <Tr>
                  <Th>QTY</Th>
                  <Th>DESCRIPTION</Th>
                  <Th isNumeric>AMOUNT</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listItems.map((items, key) => (
                  <Tr
                    key={key}
                    onClick={() => {
                      setSelectedItem(key);
                      setShowConfirmationModal(true);
                    }}
                  >
                    <Td>{items.qty}</Td>
                    <Td>{items.description}</Td>
                    <Td isNumeric>
                      {numberWithCommas(items.price * items.qty)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack
            flex={1}
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={10}
            py={5}
          >
            <Button colorScheme="red" onClick={handleClearList}>
              CLEAR
            </Button>

            <Button
              colorScheme="green"
              onClick={() => {
                setShowModal(true);
                setPrompt(true);
                getTotal();
              }}
            >
              SAVE
            </Button>
          </Stack>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Delete Item</ModalHeader>
              <ModalBody>
                Confirm if you want to delete this item from the list
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    // bg={primary}
                    colorScheme="red"
                    isLoading={isLoading}
                    onClick={() => {
                      handleDeleteItem(selectedItem);
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Save Transaction</ModalHeader>
              <ModalBody>
                {prompt ? (
                  <>
                    <Text>
                      Your Total is {numberWithCommas(receiptTotal)}/=
                    </Text>
                    <Text>Would you like to save this transaction?</Text>
                  </>
                ) : modalIsLoading ? (
                  <Loading />
                ) : (
                  <>
                    <Button
                      // bg={primary}
                      variant={"unstyled"}
                      onClick={() => {
                        setSellerIsCustomer(!sellerIsCustomer);
                      }}
                    >
                      {sellerIsCustomer
                        ? "Buying from a Customer"
                        : "Buying from a Supplier"}
                    </Button>
                    {!sellerIsCustomer ? (
                      <>
                        <Text>Pick A Supplier </Text>
                        <Select
                          // selectedValue={selectedSupplier}
                          onChange={(e) => setSelectedSupplier(e.target.value)}
                        >
                          <option value={""}>Cash</option>

                          {suppliers.map((supplier) => (
                            <option value={supplier.id.toString()}>
                              {supplier.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <>
                        <Text>Pick A Customer </Text>
                        <Select
                          onChange={(e) => setSelectedCustomer(e.target.value)}
                        >
                          {customers.map((customer) => (
                            <option
                              // label={customer.name}
                              value={customer.id.toString()}
                            >
                              {customer.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    )}
                    <br />

                    <Text>Invoice Date</Text>
                    <Stack>
                      <Input
                        placeholder="Invoice Date"
                        type="date"
                        value={invoiceDate.toISOString().substring(0, 10)}
                        onChange={handleDateChange}
                      />
                    </Stack>

                    <br />

                    <Text>Order details:</Text>
                    <Stack>
                      <CheckboxGroup
                        onChange={(values: string[]) => setGroupValues(values)}
                        value={groupValues}
                      >
                        <Checkbox value="paid" my={2}>
                          Paid
                        </Checkbox>
                        <Checkbox value="delivered" my={2}>
                          Delivered
                        </Checkbox>
                        <Checkbox value="physical" isChecked my={2}>
                          Physical Delivery Note
                        </Checkbox>
                      </CheckboxGroup>
                    </Stack>
                    <br />
                    {groupValues.includes("paid") && (
                      <>
                        <Text>Pick the account used for payment </Text>
                        <Select
                          onChange={(e) => setSelectedAccount(e.target.value)}
                        >
                          <option value={""}>{t("Pick Account")}</option>
                          {accounts.map((account) => (
                            <option
                              // label={customer.name}
                              value={account.id.toString()}
                            >
                              {account.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    )}
                    <br />

                    <Stack>
                      {groupValues.includes("physical") && (
                        <>
                          <Input
                            fontSize="md"
                            pl={5}
                            backgroundColor="transparent"
                            variant={"underlined"}
                            onChange={(e) =>
                              setRefInvoice(e.target.value)
                            }
                            placeholder={"Enter reference invoice number"}
                            // inputMode="numeric"
                          />
                          <Input
                            fontSize="md"
                            pl={5}
                            backgroundColor="transparent"
                            variant={"underlined"}
                            onChange={(e) =>
                              setDeliveryNoteNumber(e.target.value)
                            }
                            placeholder={"Enter delivery note number"}
                            inputMode="numeric"
                          />
                        </>
                      )}
                    </Stack>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    // bg={primary}
                    onClick={() => {
                      prompt ? handlePromptClose() : handleSaveData();
                    }}
                    isLoading={isLoading}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      )}
    </>
  );
};

export default function PurchaseOrder() {
  const navigate = useNavigate();
  return (
    <Center h="100dvh">
      {/* <Nav /> */}

      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        // maxWidth={"100%"}
        p={6}
        minW={"70%"}
        m="10px auto"
        as="form"
      >
        <HStack justifyContent={"space-between"} alignItems={"baseline"}>
          <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={() => navigate("/dashboard")}
            icon={<FaAngleLeft />}
            aria-label={`Go to home`}
          />

          <Stack alignItems={"flex-end"}>
            <ColorModeSwitcher mb={2} />
          </Stack>
        </HStack>
        <Form1 />
      </Box>
    </Center>
  );
}
