import { Card, CardBody, HStack, Heading, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import { numberWithCommas } from "../utils/functions";
import useCustomTranslation from "../hooks/useCustomTranslation";

type TransactionCardProps = {
  transaction: {
    name: string;
    transactionNumber: string;
    totalAmount: number;
    id: number;
    invoiceDate: Date;
    paymentStatus: boolean;
    deliveryStatus: boolean;
    transactionType: number;
    isVoid: boolean;
    traReceiptNumber: string;
    accountName: string;
  };
};

export const TransactionCard: React.FC<TransactionCardProps> = ({
  transaction: {
    name,
    transactionNumber,
    totalAmount,
    id,
    invoiceDate,
    paymentStatus,
    deliveryStatus,
    transactionType,
    isVoid,
    traReceiptNumber,
    accountName
  },
}) => {

  const { t, changeLanguage } = useCustomTranslation();

  return (
    <Card
      bg={useColorModeValue("gray.100", "gray.900")}
      borderColor={
        transactionType === 2 || transactionType === 4 ? "red" : (traReceiptNumber ? "green" : "orange")
      }
      borderRadius={10}
      borderLeftWidth={10}
      shadow={"9"}
    >
      <CardBody>
        <HStack
          py={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            flex={1}
            alignItems={"center"}
            bg={useColorModeValue("gray.100", "gray.900")}
            py={1}
            borderRadius={10}
            shadow={3}
          >
            <Stack>
              <Heading fontSize={22}>
                {moment(invoiceDate).format("DD")}
              </Heading>
            </Stack>
            <Stack>
              <Text color="grey">{moment(invoiceDate).format("MMM")}</Text>
            </Stack>
          </Stack>

          <Stack flex={10} pl={2} alignItems={"flex-start"}>
            <HStack>
              <Text fontSize={20}>{name === "" ? t("Cash") : name}</Text>
              <Text fontSize={20}>{accountName ? "- " + accountName : null}</Text>
            </HStack>
            <Stack>
              <Text>
                {transactionType === 1 || transactionType === 3
                  ? "INV"
                  : "PO"}
                {transactionNumber}
              </Text>
            </Stack>
          </Stack>

          <Stack flex={4} alignItems={"flex-end"} pr={1}>
            <HStack>
              <Stack
                borderRadius={30}
                w={2}
                bg={deliveryStatus ? "green" : "red"}
                h={2}
              />
              <Stack
                borderRadius={30}
                w={2}
                bg={paymentStatus ? "green" : "red"}
                h={2}
              />
            </HStack>
            <Text
              fontSize={20}
              colorScheme={
                transactionType === 1 || transactionType === 3 ? "green" : "red"
              }
            >
              {transactionType === 1 || transactionType === 3 ? "+" : "-"}
              {numberWithCommas(totalAmount)}/=
            </Text>
          </Stack>
        </HStack>
        {isVoid && (
          <Text
            position={"absolute"}
            right={0}
            left={0}
            top={0}
            bottom={0}
            textAlign={"center"}
            sx={{
              transform: "rotate(-45deg)",
            }}
            fontSize={50}
            color={"red"}
          >
            VOID
          </Text>
        )}
      </CardBody>
    </Card>
  );
};
