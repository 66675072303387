import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import Nav from "../components/navbar";
import { AccountList } from "../utils/types";
import { getAccountsList } from "../utils/API_calls";
import useCustomTranslation from "../hooks/useCustomTranslation";

const AccountTransferScreen = () => {
  const [notes, setNotes] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [accountTo, setAccountTo] = useState("");
  const [accountFrom, setAccountFrom] = useState("");
  const [amount, setAmount] = useState<number>(0);

  const { t, changeLanguage } = useCustomTranslation();

  const today = new Date();
  today.setDate(today.getDate());

  const [date, setDate] = useState<string>(today.toISOString());

  const toast = useToast();

  useEffect(() => {
    retrieveAccounts();
  }, []);

  const retrieveAccounts = async () => {
    setIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setIsLoading(false);
  };

  const handleSaveData = async () => {
    if (accountTo === accountFrom) {
      toast({
        title: t("You cannot transfer to the same account"),
        status: "error",
      });
      return;
    }

    if (accountTo === "" || accountFrom === "") {
      toast({
        title: t("Please pick an account"),
        status: "error",
      });
      return;
    }

    if (amount === 0) {
      toast({
        title: t("Please fill in the amount"),
        status: "error",
      });
      return;
    }

    const data = {
      accountFrom: accountFrom,
      accountTo: accountTo,
      amount: amount,
      date: date,
      notes: notes,
    };

    setIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/accounts/internalTransfer`,
        data,
        { headers }
      );
      console.log(response.data);
      setIsLoading(false);
      toast({
        title: t("Transfer Details Saved!"),
        status: "success",
      });
      await retrieveAccounts();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      await retrieveAccounts();
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Internal Transfers"}/>
      </Stack>
      {isLoading ? (
        <Center h={600}>
          <Loading />
        </Center>
      ) : (
        <Box
          borderWidth="1px"
          rounded="lg"
          shadow="1px 1px 3px rgba(0,0,0,0.3)"
          p={6}
          m="10px auto"
          as="form"
        >
          <Stack alignItems={"center"} pb={10} flex={1} >
            <Text fontSize="lg" fontFamily="">
              {t("Amount")}
            </Text>
            <Stack  flex={1}>
              <Stack  minW={300}>
                <Input
                  fontSize="md"
                  pl={5}
                  backgroundColor="transparent"
                  variant={"outline"}
                  onChange={(e) => setAmount(parseInt(e.target.value))}
                  inputMode="numeric"
                />
              </Stack>
            </Stack>

            <Text fontSize="lg" fontFamily="">
              {t("Account From")}
            </Text>
            <Stack minW={300}>
              <Select onChange={(e) => setAccountFrom(e.target.value)}>
                <option value={""}>{t("Pick Account")}</option>
                {accounts.map((account) => (
                  <option value={account.id.toString()}>{account.name}</option>
                ))}
              </Select>
            </Stack>

            <Text fontSize="lg" fontFamily="">
              {t("Account To")}
            </Text>
            <Stack minW={300}>
              <Select onChange={(e) => setAccountTo(e.target.value)}>
                <option value={""}>{t("Pick Account")}</option>
                {accounts.map((account) => (
                  <option value={account.id.toString()}>{account.name}</option>
                ))}
              </Select>
            </Stack>

            <Text fontSize="lg" fontFamily="">
              {t("Notes")}
            </Text>
            <Stack>
              <Stack minW={300}>
                <Input
                  fontSize="md"
                  pl={5}
                  backgroundColor="transparent"
                  variant={"outline"}
                  onChange={(e) => setNotes(e.target.value)}
                  inputMode="numeric"
                />
              </Stack>
            </Stack>

            <Text fontSize="lg" fontFamily="">
              {t("Date")}
            </Text>

            <Stack minW={300}>
              <Input
                placeholder="Start Date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack alignItems={"center"}>
            <Button
              variant={"solid"}
              onClick={() => handleSaveData()}
              borderRadius={20}
              width={200}
            >
              <Text>{t("Save")}</Text>
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default AccountTransferScreen;
