import { useState } from "react";
import {
  Box,
  Button,
  Center,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import Nav from "../components/navbar";
import useCustomTranslation from "../hooks/useCustomTranslation";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confPassword, setConfPassword] = useState<string>("");

  const toast = useToast();

  const { t, changeLanguage } = useCustomTranslation();

  const updatePassword = async () => {
    setIsLoading(true);

    if (newPassword !== confPassword) {
      setIsLoading(false);
      toast({
        title: t("Passwords don't match"),
        status: "error",
      });
      return;
    }

    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/auth/changePassword`,
        { newPassword: newPassword, oldPassword: oldPassword },
        { headers }
      );
      console.log(response.data);
      if (response.data.errorCode === 0) {
        setIsLoading(false);
        toast({
          title: t("Password updated"),
          status: "success",
        });
      } else {
        setIsLoading(false);
        toast({
          title: t("Password update failed, try again later"),
          description: response.data.message,
          status: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Change Password"}/>
      </Stack>
      {isLoading ? (
        <Center h={600}>
          <Loading />
        </Center>
      ) : (
        <>
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            p={6}
            m="10px auto"
            as="form"
          >
            <Stack px={5} alignItems={"center"} pb={10} flex={1}>
              <Text fontSize="lg" fontFamily="">
                {t("Old Password")}
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    type="password"
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </Stack>
              </Stack>

              <Stack h={10} />

              <Text fontSize="lg" fontFamily="">
                {t("New Password")}
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    type="password"
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Stack>
              </Stack>

              <Stack h={10} />
              <Text fontSize="lg" fontFamily="">
                {t("Confirm Password")}
              </Text>
              <Stack width={300}>
                <Stack width={300}>
                  <Input
                    type="password"
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"outline"}
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack h={10} />
            <Stack alignItems={"center"}>
              <Button
                colorScheme="blue"
                onClick={() => updatePassword()}
                width={200}
              >
                <Text>{t("Save")}</Text>
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ChangePassword;
