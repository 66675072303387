import {
  Button,
  Card,
  Collapse,
  HStack,
  Skeleton,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest, numberWithCommas } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import moment from "moment";

type DataPoint = {
  primary: string; // Ensure primary is a string (month names)
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type ApiResponse = {
  orders: number[];
  payments: number[];
  expenses: number[];
};

const processData = (
  data: ApiResponse,
  t: (key: string) => string
): Series[] => {
  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];
  return [
    {
      label: t("Orders"),
      data: data.orders.map((value, index) => ({
        primary: months[index],
        secondary: value,
      })),
    },
    {
      label: t("Payments"),
      data: data.payments.map((value, index) => ({
        primary: months[index],
        secondary: value,
      })),
    },
    {
      label: t("Expenses"),
      data: data.expenses.map((value, index) => ({
        primary: months[index],
        secondary: value,
      })),
    },
  ];
};

export default function Bar() {
  const [data, setData] = useState<Series[]>([]);
  const { t, changeLanguage } = useCustomTranslation();
  const [orderSum, setOrderSum] = useState<number>(0);
  const [paymentSum, setPaymentSum] = useState<number>(0);
  const [expenseSum, setExpenseSum] = useState<number>(0);
  const [more, setMore] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = await getHeadersForRequest();

        const response = await axios.get<ApiResponse>(
          `${process.env.REACT_APP_TEST_API_URL}/analytics/monthly/all`,
          {
            headers,
          }
        );
        console.log("API Response:", response.data); // Log API response
        const processedData = processData(response.data, t);
        console.log("Processed Data:", processedData); // Log processed data
        setData(processedData);

        const totalOrders = response.data.orders.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const totalPayments = response.data.payments.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const totalExpenses = response.data.expenses.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setPaymentSum(totalPayments);
        setExpenseSum(totalExpenses);
        setOrderSum(totalOrders);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [t]);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band", // Set the scale type explicitly
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear", // Set the scale type explicitly
      },
    ],
    []
  );

  return (
    <Skeleton
      // height="40px"
      isLoaded={isLoaded}
      fadeDuration={4}
      // bg="blue.500"
      // color="white"
    >
      <Card>
        <Stack w="100%" h={500}>
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              dark: useColorModeValue(false, true),
            }}
          />
        </Stack>
        <Stack alignItems={"end"} pt={5}>
          <Button onClick={() => setMore(!more)}>
            {more ? "View Less" : "View More"}
          </Button>
        </Stack>
        <Collapse in={more} animateOpacity>
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Sales</Th>
                  <Th>Payments</Th>
                  <Th>Expenses</Th>
                  <Th>Profit/Loss</Th>
                  <Th>Avg Sales</Th>
                  <Th>Avg Payments</Th>
                  <Th>Avg Expenses</Th>
                  <Th>Avg Profit/Loss</Th>
                  <Th>Performace Rating</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{numberWithCommas(orderSum)}</Td>
                  <Td>{numberWithCommas(paymentSum)}</Td>
                  <Td>{numberWithCommas(expenseSum)}</Td>
                  <Td>{numberWithCommas(orderSum - expenseSum)}</Td>
                  <Td>{numberWithCommas(Math.round(orderSum / parseInt(moment(new Date()).format("MM"))))}</Td>
                  <Td>{numberWithCommas(Math.round(paymentSum / parseInt(moment(new Date()).format("MM"))))}</Td>
                  <Td>{numberWithCommas(Math.round(expenseSum / parseInt(moment(new Date()).format("MM"))))}</Td>
                  <Td>
                    {numberWithCommas(Math.round((orderSum - expenseSum) / parseInt(moment(new Date()).format("MM"))))}
                  </Td>
                  <Td>
                    {orderSum - expenseSum < 0
                      ? "Poor"
                      : orderSum - expenseSum < 500000
                      ? "Moderate"
                      : "Good"}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Collapse>
      </Card>
    </Skeleton>
  );
}
