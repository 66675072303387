import {
  Card,
  CardBody,
  HStack,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { numberWithCommas } from "../utils/functions";

type PaymentCardProps = {
  payment: {
    customerId: number;
    id: number;
    isVoid: boolean;
    name: string;
    notes: string;
    paymentDate: Date;
    paymentType: number;
    supplierId: number;
    totalAmount: number;
    transactionDate: Date;
    userId: string;
  };
};

export const PaymentCard: React.FC<PaymentCardProps> = ({
  payment: {
    customerId,
    id,
    isVoid,
    name,
    notes,
    paymentDate,
    paymentType,
    supplierId,
    totalAmount,
    transactionDate,
    userId,
  },
}) => {
  return (
    <Card
      bg={useColorModeValue("gray.100", "gray.900")}
      borderColor={supplierId ? "red" : "green"}
      borderRadius={10}
      borderLeftWidth={10}
      shadow={"9"}
      
    >
      <CardBody>
        <HStack
          py={2}
          justifyContent={"space-between"}
          px={1}
        >
          <Stack
            flex={1}
            alignItems={"center"}
            py={1}
            borderRadius={10}
          >
            <Stack>
              <Heading fontSize={22}>
                {moment(paymentDate).format("DD")}
              </Heading>
            </Stack>
            <Stack>
              <Text color="grey">{moment(paymentDate).format("MMM")}</Text>
            </Stack>
          </Stack>

          <Stack flex={10} pl={2} alignItems={"flex-start"}>
            <Stack>
              <Text fontSize={20}>{name === "" ? "Cash" : name}</Text>
              <Text fontSize={12}>{notes ? notes : null}</Text>
            </Stack>
            <Stack>
            </Stack>
          </Stack>

          <Stack flex={4} alignItems={"flex-end"} pr={1}>
            <Text fontSize={20} color={customerId ? "green" : "red"}>
              {customerId ? "+ " : "- "}
              {numberWithCommas(totalAmount)}/=
            </Text>
          </Stack>
        </HStack>
        {isVoid && (
          <Text
            position={"absolute"}
            right={0}
            left={0}
            top={0}
            bottom={0}
            textAlign={"center"}
            sx={{
              transform: "rotate(-45deg)",
            }}
            fontSize={50}
            color={"red"}
          >
            VOID
          </Text>
        )}
      </CardBody>
    </Card>
  );
};
